import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, BlankLayout } from "./layouts";

// Route Views
import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import Articles from "./views/Articles";
import Accounts from "./views/Accounts";
import NewAccount from "./views/newAccount"
import UserProfile from "./views/UserProfile";
import NewArticle from "./views/newArticle";
import Article from "./views/Article";
import Partners from "./views/Partners";
import CompanyProfile from "./views/CompanyProfile";
import NewCompany from "./views/newCompany";
import PremiumResources from "./views/PremiumResources";
import BoardMembers from "./views/BoardMembers";
import NewBoardMember from "./views/NewBoardMember";
import BoardMember from "./views/BoardMember";
import BatchInsertUsers from "./views/BatchInsertUsers";
import ArticleCategories from "./views/ArticleCategories";

// const withAuth = (fromPath, Component, props) => {
//   // const authData = await AuthenticationService.getAuthenticationInfo();
//   // if (authData.isSignedIn) {
//   //   return (<Component {...props}/>);
//   // } else {
//   //   return (<Redirect to={{
//   //     pathname: "/login",
//   //     state: { from: fromPath }
//   //   }} />);
//   // }
//   // const x = false;
//   // if (x) {
//   //   return <Redirect to={{ pathname: "/blog-overview", state: { from: fromPath } }}/>;
//   // } else {
//   //   return <Redirect to={{ pathname: "/login", state: { from: fromPath } }}/>;
//   // }

//   return () => (
//     <Authentication fromPath={fromPath} ComponentToRender={Component} componentProps={props}/>
//   );
// };

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => (<Redirect to="/dashboard" />)
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard,
  },
  {
    path: "/login",
    exact: true,
    layout: BlankLayout,
    component: Login,
    noAuth: true
  },
  {
    path: "/accounts",
    layout: DefaultLayout,
    component: Accounts,
    exact:true,
  },
  {
    path: "/accounts/new",
    layout: DefaultLayout,
    component: NewAccount,
    exact:true,
  },
  {
    path: "/accounts/batch-insert",
    layout: DefaultLayout,
    component: BatchInsertUsers,
    exact: true
  },
  {
    path: "/accounts/:userId",
    layout: DefaultLayout,
    component: UserProfile
  },
  {
    path: "/articles",
    layout: DefaultLayout,
    component: Articles,
    exact:true,
  },
  {
    path: "/articles/new",
    layout: DefaultLayout,
    component: NewArticle,
    exact:true
  },
  {
    path: "/articles/:articleId",
    layout: DefaultLayout,
    component: Article
  },
  {
    path: "/article-categories",
    layout: DefaultLayout,
    component: ArticleCategories,
    exact:true,
  },
  {
    path: "/partners",
    layout: DefaultLayout,
    component: Partners,
    exact:true
  },
  {
    path: "/partners/new",
    layout: DefaultLayout,
    component: NewCompany,
    exact:true,
  },
  {
    path: "/partners/:companyId",
    layout: DefaultLayout,
    component: CompanyProfile
  },
  {
    path: "/premium-resources",
    layout: DefaultLayout,
    component: PremiumResources
  },
  {
    path: "/board-members",
    layout: DefaultLayout,
    component: BoardMembers,
    exact:true
  },
  {
    path: "/board-members/new",
    layout: DefaultLayout,
    component: NewBoardMember,
    exact:true,
  },
  {
    path: "/board-members/:boardMemberId",
    layout: DefaultLayout,
    component: BoardMember
  },
];
