export default {
  GetArticleCategories: "/public/article-category/all",
  CreateArticleCategory: "/admin/article-category/new",
  GetArticleCategoryById: "/admin/article-category/{categoryId}",
  UpdateArticleCategory: "/admin/article-category/{categoryId}/update",
  DeleteArticleCategory: "/admin/article-category/{categoryId}/delete",
  PaginateArticles: "/public/article/paginate?p={p}&n={n}",
  PaginateArticlesByCategory: "/public/article/paginate?p={p}&n={n}&c={c}",
  PaginateInactiveArticles: "/admin/article/inactive/paginate?p={p}&n={n}",
  GetArticleDetailsBySlug: "/article/{urlSlug}",
  CreateArticle: "/admin/article/new",
  GetArticleDetailsById: "/admin/article/{articleId}",
  UpdateArticle: "/admin/article/{articleId}/update",
  DeleteArticle: "/admin/article/{articleId}/delete",
  CreateArticleMedia: "/admin/article/{articleId}/new-media",
  DeleteArticleMedia: "/admin/article/delete-media/{articleMediaId}"
};