import React,{useState,useEffect} from "react";
import { Container, Row, Col,
  Card,CardHeader,CardBody,
  Button,
} from "shards-react";

import Pagination from "../components/common/Pagination";
import PageTitle from "../components/common/PageTitle";
import {Link} from "react-router-dom";

import moment from "moment";

import handleError from "../services/ErrorHandlerService";

import RestService from "../services/RestService";
import UserEndpoints from "../data/api-endpoints/user";
import UrlResolverService from "../services/UrlResolverService";

const Dashboard = () => {

  const [users, setUsers] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  async function paginateUsersByBirthday(page) {
    try {
      const response = await RestService.GetRequest(UrlResolverService.resolve(
        UserEndpoints.PaginateUsersByBirthday,
        { p: page, n: itemsPerPage }
      ));
      
      setUsers(response.data.page.content);
      setTotalPages(response.data.page.totalPages);

      return response;
    } catch (e) {
      handleError(e);
    }
  }

  async function handlePagination(page) {
    await paginateUsersByBirthday(page);
  }

  useEffect(() => {
    paginateUsersByBirthday(1);
  }, []);

  function renderUsers() {
    return users.map((user, i) => (
      <tr key={i}>
        <td>{user.user.username}</td>
        <td>{`${user.firstName} ${user.lastName}`}</td>
        <td>{user.user.email}</td>
        <td>
            <Link to={"/accounts/"+user.user.id} style={{marginRight:10}}>
                <Button style={{fontSize:14}}>View Profile</Button>
            </Link> 
        </td>
      </tr>
    ));
  }

  return(
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Dashboard" subtitle="Admin" className="text-sm-left mb-3" />
      </Row>

      <Row className="justify-content-center">
        <Col md="10">
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
                <h6 className="m-0">This Month's Birthday ({moment(new Date()).format("MMMM YYYY")})</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Username
                    </th>
                    <th scope="col" className="border-0">
                      Full Name
                    </th>
                    <th scope="col" className="border-0">
                      Email
                    </th>
                    <th scope="col" className="border-0">
                      Link
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {renderUsers()}
                </tbody>
              </table>

              <nav style={{cursor:'pointer'}}>
                <Pagination totalPages={totalPages} pageNeighbors={2} onPageChanged={handlePagination}/>
              </nav>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );

};

export default Dashboard;