export default function() {
  return [
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">home</i>',
      htmlAfter: ""
    },
    {
      title: "Articles",
      htmlBefore: '<i class="material-icons">edit</i>',
      to: "/articles",
    },
    {
      title: "Partners",
      htmlBefore: '<i class="material-icons">business</i>',
      to: "/partners",
    },
    {
      title: "Members",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/accounts",
    },
    {
      title: "Premium Resources",
      htmlBefore: '<i class="material-icons">folder_shared</i>',
      to:"/premium-resources"
    },
    {
      title: "Board Members",
      htmlBefore: '<i class="material-icons">people</i>',
      to:"/board-members"
    },
    
  ];
}




