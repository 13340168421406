import React, { useState, useEffect } from "react";
import { Container, Row, Col,Card,CardHeader,CardBody,Button,Modal,ModalHeader,ModalBody } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Pagination from "../components/common/Pagination";
import NavbarSearch from "../components/layout/MainNavbar/NavbarSearch";
import {Link} from "react-router-dom";

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import UserEndpoints from "../data/api-endpoints/user";
import UrlResolverService from "../services/UrlResolverService";

function Accounts(){

    const [users, setUsers] = useState([]);

    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [tempSearchQuery, setTempSearchQuery] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    // const [welcomeEmailDialogIsActive, setWelcomeEmailDialogIsActive] = useState(false);
    // const [sendEmailButtonIsActive, setSendEmailButtonIsActive] = useState(true);
    // const [sendEmailButtonText, setSendEmailButtonText] = useState("Send");
    // const [welcomeEmailDialogText, setWelcomeEmailDialogText] = useState("Are you sure you want to send welcome email to All Users?");

    useEffect(() => {
      paginateUsers(1);
    }, []);

    async function paginateUsers(page, query = null) {
      try {
        let response;
        if (query) {
          response = await RestService.GetRequest(UrlResolverService.resolve(
            UserEndpoints.PaginateUserSearches,
            { p: page, n: itemsPerPage, q: query }
          ));
        }
        else {
          response = await RestService.GetRequest(UrlResolverService.resolve(
            UserEndpoints.PaginateUsers,
            { p: page, n: itemsPerPage }
          ));
        }
        
        setUsers(response.data.page.content);
        setTotalPages(response.data.page.totalPages);

        return response;
      } catch (e) {
        handleError(e);
      }
    }


    async function handlePagination(page) {
      if (searchQuery) {
        await paginateUsers(page, searchQuery);
      } else {
        await paginateUsers(page);
      }
    }

    function renderUsers() {
      return users.map((user, i) => (
        <tr key={i}>
          <td>{user.firstName} {user.lastName}</td>
          <td>{user.user.username}</td>
          <td>{user.user.email}</td>
          <td>{user.user.isActive ? "active": "suspended" }</td>
          <td>
              <Link to={"/accounts/"+user.user.id} style={{marginRight:10}}>
                  <Button style={{fontSize:14}}>View Profile</Button>
              </Link> 
          </td>
        </tr>
      ));
    }

    function handleSearchBarSubmit(e) {
      e.preventDefault();
      setSearchQuery(tempSearchQuery);
      paginateUsers(1, tempSearchQuery);
    }

    // function showWelcomeEmailDialog() {
    //   setWelcomeEmailDialogIsActive(true);
    // }

    // function hideWelcomeEmailDialog() {
    //   setWelcomeEmailDialogIsActive(false);
    // }

    // async function handleSendWelcomeEmail() {
    //   try {
    //     setModalControlDisabled(true);
    //     const response = await RestService.PostRequest(UrlResolverService.resolve(
    //       UserEndpoints.SendWelcomeEmails,
    //       {}
    //     ));
  
    //     hideWelcomeEmailDialog();
    //     setModalControlDisabled(false);
    //     window.flashMessage(response.data.resultStatus.message);
    //   } catch (error) {
    //     handleError(error);
    //   }
    // }

    // function setModalControlDisabled(isDisabled) {
    //   setSendEmailButtonIsActive(isDisabled);
    //   if (isDisabled) {
    //     setWelcomeEmailDialogText("Sending Email. This may take a while.");
    //     setSendEmailButtonText("Sending...");
    //   } else {
    //     setWelcomeEmailDialogText("Are you sure you want to send welcome email to All Users?");
    //     setSendEmailButtonText("Send");
    //   }
    // }

return(
    <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Member List" subtitle="Member" className="text-sm-left" />
    </Row>
    
    <Row>
      {/* Editor */}
      <Col lg="12" md="12">
        <Row className="mb-2">
            <Col md="4">
                <Card small className="p-1">
                    <NavbarSearch 
                    placeholder="Search users..." 
                    value={tempSearchQuery} 
                    onChange={(e) => { setTempSearchQuery(e.target.value); }}
                    onSubmit={handleSearchBarSubmit}
                    />
                </Card>
            </Col>
            <Col>
                <div className="float-right" style={{marginLeft: "10px"}}>
                    <Link to="/accounts/new">
                        <Button style={{fontSize:16}} theme="success">Add New Account</Button>
                    </Link>
                </div>
                <div className="float-right" style={{marginLeft: "10px"}}>
                    <Link to="/accounts/batch-insert">
                        <Button style={{fontSize:16}} theme="success">Batch Upload</Button>
                    </Link>
                </div>
                {/* <div className="float-right">
                  <Button style={{fontSize:16}} theme="info" onClick={showWelcomeEmailDialog}>Send Welcome Email</Button>
                </div> */}
            </Col>
        </Row>
        <Card small className="mb-4">
            <CardHeader className="border-bottom">
                <h6 className="m-0">Active Users</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                <thead className="bg-light">
                    <tr>
                    <th scope="col" className="border-0">
                        Name
                    </th>
                    <th scope="col" className="border-0">
                        Username
                    </th>
                    <th scope="col" className="border-0">
                        Email
                    </th>
                    <th scope="col" className="border-0">
                        Status
                    </th>
                    <th scope="col" className="border-0">
                        Action
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {renderUsers()}
                </tbody>
                </table>
                
                <nav style={{cursor:'pointer'}}>
                  <Pagination totalPages={totalPages} pageNeighbors={2} onPageChanged={handlePagination}/>
                </nav>
                
            </CardBody>
        </Card>
      </Col>

    </Row>

    {/* <Modal open={welcomeEmailDialogIsActive} toggle={hideWelcomeEmailDialog}>
        <ModalHeader style={{display:'block'}}>
            Send Welcome Email
            <span className="float-right p-1" style={{cursor:'pointer'}} onClick={hideWelcomeEmailDialog}>X</span>
        </ModalHeader>
        <ModalBody>
            <Row>
              <div className="mb-4">{welcomeEmailDialogText}</div>
            </Row>
            <Row>
              <Col md={6}>
                <Button isActive={sendEmailButtonIsActive} block style={{fontSize:14}} theme="primary" onClick={handleSendWelcomeEmail}>{sendEmailButtonText}</Button>
              </Col>
              <Col md={6}>
                <Button block style={{fontSize:14}} theme="secondary" onClick={hideWelcomeEmailDialog}>Cancel</Button>
              </Col>
            </Row>
        </ModalBody>
    </Modal> */}
  </Container>
 )
};

export default Accounts;
