import React,{useState,useEffect} from "react";
import { Redirect } from 'react-router-dom';
import {
    Container,
    Card,CardHeader,
    ListGroup,ListGroupItem,
    Row,Col,
    Form,FormFeedback,FormInput,FormSelect,FormCheckbox,
    Button
  } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {Link} from "react-router-dom";
import AsyncSelect from "react-select/async";

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import UserEndpoints from "../data/api-endpoints/user";
import CompanyEndpoints from "../data/api-endpoints/company";
import UrlResolverService from "../services/UrlResolverService";

import base64Encode from "../services/ImageBase64EncoderService";

const placeholderImage = "https://via.placeholder.com/200";

function newAccount(){

  const [roles, setRoles] = useState([]); 
  const [roleId, setRoleId] = useState(0); //value is integer id
  const [selectedCompanyOption, setSelectedCompanyOption] = useState(null); // value is object {value: , label: }
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [position, setPosition] = useState(null);
  const [contactNo, setContactNo] = useState(null);
  const [dob, setDob] = useState(null);
  const [isActive, setIsActive] = useState(true);
  
  const [redirect, setRedirect] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonMessage, setButtonMessage] = useState("Submit");

  const [profileImg, setProfileImg] = useState(null);
  const [profileImgFileName, setProfileImgFileName] = useState("Select an Image");
  const [placeholderImg, setPlaceholderImg] = useState(placeholderImage);

  let timeout = null;

  async function fetchUserPermissions() {
    try {
      const response = await RestService.GetRequest(UserEndpoints.GetAllUserPermissions);
      setRoles(response.data.roles);
      setRoleId(response.data.roles[0].id);
    } catch (e) {
      handleError(e);
    }
  }

  useEffect(() => {
    fetchUserPermissions();
  }, []);

  const mapCompanyNames = (results) => {
    return results.map(result => ({
      value: result.id,
      label: result.companyName
    }));
  };

  function fetchCompanyNames(inputValue, callback) {
    timeout = setTimeout(() => {
      RestService.GetRequest(UrlResolverService.resolve(
        CompanyEndpoints.SearchCompanyName,
        { q: inputValue }
      )).then(response => {

        callback(mapCompanyNames(response.data.results));

      }).catch(e => {
        handleError(e);
      });
    }, 1000);
  }

  function handleCompanyInputChange(input) {
    if (timeout != null) {
      clearTimeout(timeout);
    } 

    const inputValue = input.replace(/[^a-zA-Z0-9 ]/g, '');
    return inputValue;
  }

  function renderRoles() {
    return roles.map((role, i) => (
      <option key={i} value={role.id}>{role.roleName}</option>
    )); 
  }

  function buttonIsDisabled(disabled) {
    setButtonDisabled(disabled);
    if (disabled) {
      setButtonMessage("Submitting...");
    } else {
      setButtonMessage("Submit");
    }
  }

  function handleProfileImgInput(e) {
    setProfileImg(e.target.files[0]); 
    setProfileImgFileName(e.target.files[0].name);
    setPlaceholderImg(URL.createObjectURL(e.target.files[0]));
  }

  async function submitForm(e) {
    buttonIsDisabled(true);
    e.preventDefault();
    if (password.length >= 8 && password === confirmPassword) {
      if (roleId !==0 && username && email && firstName) {
        try {

          const data = {
            roleId,
            username,
            email,
            password,
            companyId: (selectedCompanyOption? selectedCompanyOption.value: null),
            isActive,
            firstName,
            lastName,
            position,
            contactNo,
            dob,
          };
          if (profileImg) data.profileImg = await base64Encode(profileImg, 600, 600);

          const response = await RestService.PostRequest(
            UserEndpoints.CreateUser, 
            data
          );
          
          buttonIsDisabled(false);
          window.flashMessage(response.data.resultStatus.message);
          setRedirect(true);
        } catch (e) {
          handleError(e, [
            { stringToken: "[email]", message: "Email already exists." },
            { stringToken: "[username]", message: "Username already exists." }
          ]);
          buttonIsDisabled(false);
        }
      }
    }
  }

  const renderRedirect = () => {
    if (redirect) return <Redirect to="/accounts"/>;
  }

  return (
      <Container fluid className="main-content-container px-4 pb-4">
        {renderRedirect()}
        {/* Page Header */}
        <Row noGutters className="page-header pt-4 pb-2">
            <PageTitle sm="4" title="Register New Member" subtitle="Member" className="text-sm-left" />
        </Row>
        <div className="mb-2"><Link to="/accounts"> &#8636; Go Back</Link></div>

        
        <Row className="justify-content-center">
          <Col lg={8}>
              <Card small className="mb-4 ">
                  <CardHeader className="border-bottom">
                  <h6 className="m-0">New Account Registration</h6>
                  </CardHeader>

                  <ListGroup flush>
                      <ListGroupItem className="p-3">
                      <Form onSubmit= {submitForm}>
                          <Row form>
                              <Col md={4} className="form-group">
                                  <label>Role</label><label style={{color: "red"}}>*</label>
                                  <FormSelect value={roleId} onChange={(e) => {setRoleId(e.target.value)}}>
                                    {renderRoles()}
                                  </FormSelect>
                                  <FormFeedback>Please select a role.</FormFeedback>
                              </Col>
                          </Row>
                          <Row form>
                              <Col md={6} className="form-group">
                                  <label>First Name</label><label style={{color: "red"}}>*</label>
                                  <FormInput required onChange={(e) => {setFirstName(e.target.value)}}/>
                                  <FormFeedback></FormFeedback>
                              </Col>
                              <Col md={6} className="form-group">
                                  <label>Last Name</label>
                                  <FormInput onChange={(e) => {setLastName(e.target.value)}}/>
                                  <FormFeedback></FormFeedback>
                              </Col>
                          </Row>
                          <Row form>
                              <Col md={6} className="form-group">
                                  <label>Email</label><label style={{color: "red"}}>*</label>
                                  <FormInput required type="email" onChange={(e) => {setEmail(e.target.value)}}/>
                                  <FormFeedback></FormFeedback>
                              </Col>
                              <Col md={6} className="form-group">
                                  <label>Username</label><label style={{color: "red"}}>*</label>
                                  <FormInput required onChange={(e) => {setUsername(e.target.value)}}/>
                                  <FormFeedback></FormFeedback>
                              </Col>
                          </Row>
                          <Row form>
                              <Col md={6} className="form-group">
                                  <label>Password</label><label style={{color: "red"}}>*</label>
                                  <FormInput required type="password" onChange={(e) => {setPassword(e.target.value)}} invalid={(password.length !==0 && password.length < 8)}/>
                                  <FormFeedback>Password needs to be 8 characters long.</FormFeedback>
                              </Col>
                              <Col md={6} className="form-group">
                                  <label>Confirm Password</label><label style={{color: "red"}}>*</label>
                                  <FormInput required type="password" onChange={(e) => {setConfirmPassword(e.target.value)}} invalid={(confirmPassword.length !==0 && password !== confirmPassword)} />
                                  <FormFeedback>Password does not match.</FormFeedback>
                              </Col>
                          </Row>
                          <Row form>
                              <Col md={12} className="form-group">
                                  <label>Partner Company</label>
                                  <AsyncSelect 
                                    value = {selectedCompanyOption}
                                    placeholder="Search" 
                                    cacheOptions 
                                    loadOptions={fetchCompanyNames}  
                                    onInputChange={handleCompanyInputChange}
                                    onChange={(option) => {setSelectedCompanyOption(option)}}
                                    onFocus={() => { setSelectedCompanyOption(null) }}
                                  />
                              </Col>
                          </Row>
                          <Row form>
                              <Col md={6} className="form-group">
                                  <label>Position</label>
                                  <FormInput onChange={(e) => {setPosition(e.target.value)}}/>
                              </Col>
                              <Col md={6} className="form-group">
                                  <label>Phone</label>
                                  <FormInput onChange={(e) => {setContactNo(e.target.value)}}/>
                              </Col>
                          </Row>
                          <Row form>
                              <Col md={12} className="form-group">
                                  <label>Date of Birth </label>
                                  <FormInput type="date" onChange={(e) => {setDob(e.target.value) }}/>
                              </Col>
                          </Row>

                          <Row form style={{ marginBottom: "30px" }}>
                            <Col md={6} className="form-group">
                              <label>Profile Image</label>
                              <div className="custom-file mb-3">
                                <input type="file" accept="image/*" className="custom-file-input" onChange={handleProfileImgInput} />
                                <label className="custom-file-label">
                                {profileImgFileName}
                                </label>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3 d-flex justify-content-center">
                                <img
                                src={placeholderImg}
                                style={{objectFit: "contain"}}
                                width="200"
                                height="200"
                                alt="img"
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row form>
                            <Col className="form-group">
                              <FormCheckbox checked={isActive} onChange={() => {setIsActive(!isActive)}} >
                                Immediately activate user
                              </FormCheckbox>
                            </Col>
                          </Row>

                          <div><span style={{color: "red"}}>*</span> Required Field</div>
                          <div className="d-flex justify-content-center mb-2">
                              <Button disabled={buttonDisabled} block style={{fontSize:16}} type="submit">{buttonMessage}</Button>
                          </div>
                      </Form>
                  </ListGroupItem>
                  </ListGroup>
              
              </Card>
          </Col>
      </Row>
    </Container>
  );
}

export default newAccount;
