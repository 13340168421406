import React,{useState,useEffect} from "react";
import { Container, Row, Col,
  Card,CardHeader,CardBody,CardImg,
  Button,
  Modal, ModalHeader, ModalBody
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import {Link} from 'react-router-dom';

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import BoardMemberEndpoints from "../data/api-endpoints/board-member";
import UrlResolverService from "../services/UrlResolverService";

const imageStyle= {height:150,objectFit:'cover'}

const placeholderImage = require("../images/placeholder/no_image_placeholder.png");

function BoardMember() {
  const [boardMembers, setBoardMembers] = useState([]);

  const [openDeleteBoardMemberModal, setOpenDeleteBoardMemberModal] = useState(false);
  const [boardMemberIdToDelete, setBoardMemberIdToDelete] = useState(null);

  async function fetchAllBoardMembers() {
    try {
      const response = await RestService.GetRequest(BoardMemberEndpoints.GetAllBoardMembers);
      setBoardMembers(response.data.members);
    } catch (error) {
      handleError(error);
    }
  }
  
  useEffect(() => {
    fetchAllBoardMembers();
  }, []);

  function showDeleteBoardMemberModal(boardMemberId) {
    setBoardMemberIdToDelete(boardMemberId);
    setOpenDeleteBoardMemberModal(true);
  }

  function hideDeleteBoardMemberModal() {
    setBoardMemberIdToDelete(null);
    setOpenDeleteBoardMemberModal(false);
  }

  async function handleDeleteBoardMember() {
    try {
      const boardMemberId = boardMemberIdToDelete;
      const response = await RestService.DeleteRequest(UrlResolverService.resolve(
        BoardMemberEndpoints.DeleteBoardMember,
        { boardMemberId }
      ));

      setBoardMembers(() => {
        const updatedBoardMembers = boardMembers;
        for (let i = 0; i < updatedBoardMembers.length; i++) {
          if (updatedBoardMembers[i].id === boardMemberId) {
            updatedBoardMembers.splice(i,1);
            break;
          }
        }
        return updatedBoardMembers;
      });

      hideDeleteBoardMemberModal();
      window.flashMessage(response.data.resultStatus.message);
    } catch (error) {
      handleError(error);
    }
  }

  function renderBoardMembers() {
    return boardMembers.map((boardMember, i) => (
      <Col md={3} key={i}>
        <Card className="mb-4">
          <CardImg top src={boardMember.profileImgUrl? boardMember.profileImgUrl: placeholderImage} style={imageStyle}/>
          <CardBody style={{padding:20}}>
            <div style={{fontSize:16}}> {boardMember.name} </div>
            <div>
                <div className="text-muted"> {boardMember.sdiTitle} </div>
            </div>
            <div>
                <div className="text-muted"> {boardMember.originCompanyTitle} of {boardMember.originCompany} </div>
            </div>
            <div className="mt-3">
              <Row>
                  <Col>
                    <Link to= {"/board-members/"+boardMember.id}>
                      <Button block >View</Button>
                    </Link>
                  </Col>
                  <Col>
                    <Button block theme="danger" onClick={() => { showDeleteBoardMemberModal(boardMember.id) }}>Delete</Button>
                  </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    ));
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Board Member List" subtitle="Board Member" className="text-sm-left" />
      </Row>
      
      <Row className="d-flex justify-content-end mb-2">
        <Col>
          <div>
            <Link to="/board-members/new">
              <Button theme="success" style ={{fontSize:16}}className="float-right">Add New Board Member</Button>
            </Link>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <Card>
            <CardHeader className="border-bottom">
              <Row form style={{fontWeight:'normal'}}>
                <h6 className="m-0">Board Members</h6>
              </Row>
            </CardHeader>
            
            <CardBody>
              <Row>
                {renderBoardMembers()}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal open={openDeleteBoardMemberModal} toggle={hideDeleteBoardMemberModal}>
        <ModalHeader style={{display:'block'}}>
            Delete Media
            <span className="float-right p-1" style={{cursor:'pointer'}} onClick={hideDeleteBoardMemberModal}>X</span>
        </ModalHeader>
        <ModalBody>
            <Row>
              <div className="mb-4">Are you sure you want to delete this board member?</div>
            </Row>
            <Row>
              <Col md={6}>
                <Button block style={{fontSize:14}} theme="primary" onClick={handleDeleteBoardMember}>Delete</Button>
              </Col>
              <Col md={6}>
                <Button block style={{fontSize:14}} theme="secondary" onClick={hideDeleteBoardMemberModal}>Cancel</Button>
              </Col>
            </Row>
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default BoardMember; 