import React,{useState, useEffect} from "react";
import moment from "moment";
import {
    Container,
    Card,CardHeader,CardBody,
    Row,Col,
    Form,FormFeedback,FormInput,FormTextarea,
    Button,
    Modal,ModalBody,ModalHeader
  } from "shards-react";
import PageTitle from "../components/common/PageTitle";

import Pagination from "../components/common/Pagination";

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import PremiumResourceEndpoints from "../data/api-endpoints/paid-resource";
import UrlResolverService from "../services/UrlResolverService";

function PremiumResources() {
  const [resources, setResources] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [openAddResourceModal, setOpenAddResourceModal] = useState(false);
  const [newResourceLabel, setNewResourceLabel] = useState("");
  const [newResourceFile, setNewResourceFile] = useState(null);
  const [newResourceFileName, setNewResourceFileName] = useState("Please Select a File");

  const [openDeleteResourceModal, setOpenDeleteResourceModal] = useState(false);
  const [resourceIdToDelete, setResourceIdToDelete] = useState(null);
  
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const [UploadButtonMessage, setUploadButtonMessage] = useState("Submit");

  async function paginatePremiumResources(page) {
    try {
      const response = await RestService.GetRequest(
        UrlResolverService.resolve(
          PremiumResourceEndpoints.PaginatePaidResources,
          { p: page, n: itemsPerPage }
        )
      );
      setResources(response.data.page.content);
      setTotalPages(response.data.page.totalPages);
    } catch (e) {
      handleError(e);
    }
  }

  useEffect(() => {
    paginatePremiumResources(1);
  },[]);

  async function handlePagination(page) {
    await paginatePremiumResources(page);
  }

  function handleAddResourceInput(e) {
    setNewResourceFile(e.target.files[0]); 
    setNewResourceFileName(e.target.files[0].name);
  }

  function toggleAddResourceModal() {
    setNewResourceLabel("");
    setNewResourceFile(null);
    setNewResourceFileName("Please Select a File");
    setOpenAddResourceModal(!openAddResourceModal);
  }

  function showDeleteResourceModal(resourceId) {
    setOpenDeleteResourceModal(true);
    setResourceIdToDelete(resourceId);
  }

  function hideDeleteResourceModal() {
    setOpenDeleteResourceModal(false);
    setResourceIdToDelete(null);
  }

  async function handleDeleteResource() {
    try {
      const resourceId = resourceIdToDelete;
      const response = await RestService.DeleteRequest(UrlResolverService.resolve(
        PremiumResourceEndpoints.DeletePaidResource,
        {resourceId}
      ));

      for (let i = 0; i < resources.length; i++) {
        if (resources[i].id === resourceId) {
          resources.splice(i,1);
          break;
        }
      }

      hideDeleteResourceModal();
      window.flashMessage(response.data.resultStatus.message);

    } catch (e) {
      handleError(e);
    }
  }

  function uploadButtonIsDisabled(disabled) {
    setUploadButtonDisabled(disabled);
    if (disabled) {
      setUploadButtonMessage("Submitting...");
    } else {
      setUploadButtonMessage("Submit");
    }
  }

  async function submitAddResourceForm(e) {
    uploadButtonIsDisabled(true);
    e.preventDefault();
    if (newResourceLabel && newResourceFile) {
      try {
        const data = {
          fileName: newResourceLabel,
          file: newResourceFile
        };
        const response = await RestService.FormData.PostRequest(
          PremiumResourceEndpoints.CreatePaidResource,
          data
        );

        const newData = response.data;

        resources.unshift({
          id: newData.id,
          fileName: newData.fileName,
          fileExt: newData.fileExt,
          fileUrl: newData.fileUrl,
          gmtCreate: newData.gmtCreate,
          gmtModified: newData.gmtModified
        });

        toggleAddResourceModal();
        window.flashMessage(response.data.resultStatus.message);
        uploadButtonIsDisabled(false);
      } catch (e) {
        handleError(e);
        uploadButtonIsDisabled(false);
      }
    }
  }

  function renderResources() {
    return resources.map((resource, i) => (
      <tr key = {i}>
          <td>{resource.fileName}</td>
          <td>{moment(resource.gmtModified).format("MMM Do YYYY, h:mm a")}</td>
          <td>
              <a href={resource.fileUrl} style={{ marginRight: "15px" }}>
                  <Button>View</Button>
              </a>
              <Button theme="danger" onClick={() => { showDeleteResourceModal(resource.id) }}>Delete</Button>
          </td>
      </tr>
    ));
  }

  return(
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header pt-4 pb-2">
        <PageTitle sm="4" title="Premium Member Resources" subtitle="Resources List" className="text-sm-left" />
      </Row>

      <Row>
        <Col md={12}>

          <Row className="mb-2">
            <Col>
              <div className="float-right">
                  <Button style={{fontSize:16}} theme="success" onClick={toggleAddResourceModal}>Add New Premium Member Resource</Button>
              </div>
            </Col>
          </Row>

          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Premium Member Resources List</h6>
            </CardHeader>

            <CardBody className="p-0 pb-3">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      File Label
                    </th>
                    <th scope="col" className="border-0">
                      Last Modified Date
                    </th>
                    <th scope="col" className="border-0">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {renderResources()}
                </tbody>
              </table>

              <nav style={{cursor:'pointer'}}>
                <Pagination totalPages={totalPages} pageNeighbors={2} onPageChanged={handlePagination}/>
              </nav>

            </CardBody>
          </Card>

        </Col>
      </Row>

      <Modal open={openAddResourceModal} toggle={toggleAddResourceModal}>
        <ModalHeader style={{display:'block'}}>
          Add New Premium Resource
          <span className="float-right p-1" style={{cursor:'pointer'}} onClick={toggleAddResourceModal}>X</span>
        </ModalHeader>

        <ModalBody>
          <Form onSubmit= {submitAddResourceForm}>
            <Row form>
              <Col className="form-group">
                <label>File Label</label>
                <FormInput required onChange={(e)=> {setNewResourceLabel(e.target.value)}} value={newResourceLabel}/>
                <FormFeedback>File Label is required.</FormFeedback>
              </Col>
            </Row>

            <Row form>
              <Col md={12} className="form-group">
                <label>Upload File</label>
                <div className="custom-file">
                  <input required type="file" className="custom-file-input" onChange={handleAddResourceInput} />
                  <label className="custom-file-label">
                    {newResourceFileName}
                  </label>
                </div>
              </Col>
            </Row>

            <Button disabled={uploadButtonDisabled} className="mt-3" block style={{fontSize:14}} type="submit">{UploadButtonMessage}</Button>
          </Form>
        </ModalBody>
      </Modal>

      <Modal open={openDeleteResourceModal} toggle={hideDeleteResourceModal}>
        <ModalHeader style={{display:'block'}}>
          Delete Premium Member Resource
          <span className="float-right p-1" style={{cursor:'pointer'}} onClick={() => { hideDeleteResourceModal() }}>X</span>
        </ModalHeader>

        <ModalBody>
          <Row>
            <div className="mb-4">Are you sure you want to delete this file?</div>
          </Row>

          <Row>
            <Col md={6}>
              <Button block style={{fontSize:14}} theme="primary" onClick={handleDeleteResource}>Delete</Button>
            </Col>

            <Col md={6}>
              <Button block style={{fontSize:14}} theme="secondary" onClick={() => { hideDeleteResourceModal() }}>Cancel</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      
    </Container>
  );
}

export default PremiumResources