const Handle = (e, customMessageData = []) => {
  let message;
  if (e.response) {

    if (e.response.data) {

      if (e.response.data.resultStatus) {
        message = e.response.data.resultStatus.message;
      } 
      else {
        message = e.response.data.message;
      }
    } 
    else if (e.response.status === 403){
      message = "Session expired. Please refresh the page or login again.";
    } 
    else {
      message = "Bad request."; // Unknown
    }
  }
  else if (e.message) {
    message = e.message;
  }
  else {
    message = e;
  }
  for (let i = 0; i < customMessageData.length; i++) {
    const customMessage = customMessageData[i];
    if (message.includes(customMessage.stringToken)) {
      message = customMessage.message;
      
    }
    break;
  }
  window.flashMessage(message, "danger");
};

export default Handle;