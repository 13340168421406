import axios from "axios";
import TokenService from "./TokenCookieService";

const Axios = axios.create({
  withCredentials: true //TODO: Enable withCredentials
});

function buildFormData(data) {
  const formData = new FormData();

  const keys = Object.keys(data);
  for(let i = 0 ; i < keys.length ; i++ ) {
    let key = keys[i];
    const value = data[key];
    formData.append(key, value);
  }

  return formData;
}

const buildAxiosConfig = (contentType) => {
  // TODO: disabled token, use jwt_token cookie from server response instead
  // const token = TokenService.GetToken();

  const headers = {
    'Accept': 'application/json',
    'Content-Type': contentType,
  };
  // TODO: disabled token, use jwt_token cookie from server response instead
  // if (token) headers['Authorization'] = "Bearer " + token;

  return {
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers
  };

};

const GetRequest = (url) => {  
  return Axios.get(url, buildAxiosConfig('application/json'));
};

const PostRequest = (url, body) => {
  return Axios.post(url, body, buildAxiosConfig('application/json'));
};

const PutRequest = (url, body) => {
  return Axios.put(url, body, buildAxiosConfig('application/json'));
};

const DeleteRequest = (url) => {
  return Axios.delete(url, buildAxiosConfig('application/json'));
};

const formPostRequest = (url, body) => {
  return Axios.post(url, buildFormData(body), buildAxiosConfig('multiparrt/form-data'));
};

const formPutRequest = (url, body) => {
  return Axios.put(url, buildFormData(body), buildAxiosConfig('multiparrt/form-data'));
};

export default {
  GetRequest,
  PostRequest,
  PutRequest,
  DeleteRequest,
  FormData: {
    PostRequest: formPostRequest,
    PutRequest: formPutRequest
  }
};
