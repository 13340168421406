import React, {useState, useEffect} from "react";
import { Redirect } from "react-router-dom";

import AuthenticationService from "../services/AuthenticationService";

const AuthenticationMiddleware = ({fromPath, Layout, Component, props }) => {

  const [process, setProcess] = useState({
    authFail: false,
    loading: true
  });

  const [userData, setUserData] = useState({
    username: undefined,
    email: undefined
  });

  async function authenticate() {
    const authData = await AuthenticationService.getAuthenticationInfo();
    if (authData.isSignedIn) {
      if (authData.role === "admin") {
        setProcess({
          loading: false,
          authFail: false
        });
        setUserData({
          username: authData.username,
          email: authData.email
        });
      } else {
        setProcess({
          loading: false,
          authFail: true
        });
      }
    } else {
      setProcess({
        loading: false,
        authFail: true
      });
    }
  }

  useEffect(() => {
    authenticate();
  },[]);

  if (process.loading) {
    return null;
  }
  else if (process.authFail) {
    const data = {
      pathname: "/login"
    };
    if (fromPath) data['state'] = {
      from: fromPath
    };

    return (<Redirect to={{ pathname: "/login", state: { from: fromPath } }} />);
  }
  else {
    return (
      <Layout {...props} username={userData.username}>
        <Component {...props} username={userData.username}/>
      </Layout>
    );
  }
  

};

export default AuthenticationMiddleware;