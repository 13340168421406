import React,{useState, useEffect} from "react";
import {
  Container,
  Card,CardHeader,CardBody,
  Row,Col,
  FormFeedback,FormInput,
  Button,
  Modal,ModalBody,ModalHeader,
  InputGroup, InputGroupAddon
} from "shards-react";
import {Link} from "react-router-dom";
import PageTitle from "../components/common/PageTitle";

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import ArticleEndpoints from "../data/api-endpoints/article";
import UrlResolverService from "../services/UrlResolverService";

function ArticleCategories() {
  const [categories, setCategories] = useState([]);

  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);

  const [newCategory, setNewCategory] = useState("");
  const [newCategoryInvalid, setNewCategoryInvalid] = useState(false);

  async function fetchCategories() {
    try {
      const response = await RestService.GetRequest(
        ArticleEndpoints.GetArticleCategories
      );
      setCategories(response.data.categories);
    } catch (e) {
      handleError(e);
    }
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  function showDeleteCategoryModal(categoryId) {
    setOpenDeleteCategoryModal(true);
    setCategoryIdToDelete(categoryId);
  }

  function hideDeleteCategoryModal() {
    setOpenDeleteCategoryModal(false);
    setCategoryIdToDelete(null);
  }

  async function handleNewCategoryForm(e) {
    e.preventDefault();
    if (newCategory) {
      setNewCategoryInvalid(false);
      try {
        const response = await RestService.PostRequest(
          ArticleEndpoints.CreateArticleCategory,
          { categoryName: newCategory }
        );

        const data = response.data;

        categories.push({
          id: data.id,
          categoryName: data.categoryName,
          gmtCreate: data.gmtCreate,
          gmtModified: data.gmtModified
        });

        setCategories([...categories]);
        
        setNewCategory("");
        window.flashMessage(response.data.resultStatus.message);
      } catch (e) {
        handleError(e, [
          { stringToken: "[categoryName]", message: "Category name already exists." }
        ]);
      }
    } else {
      setNewCategoryInvalid(true);
    }
  }


  function handleCategoryInputOnKey(i, e) {
    const newValue = e.target.value;
    categories[i].categoryName = newValue;
    setCategories([...categories]);
  }

  async function handleDeleteCategory() {
    try {
      const categoryId = categoryIdToDelete;
      const response = await RestService.DeleteRequest(UrlResolverService.resolve(
        ArticleEndpoints.DeleteArticleCategory,
        { categoryId }
      ));
      
      setCategories(() => {
        const updatedCategories = categories;
        for (let i = 0; i < updatedCategories.length; i++) {
          if (updatedCategories[i].id === categoryId) {
            updatedCategories.splice(i,1);
            break;
          }
        }
        return updatedCategories;
      });

      hideDeleteCategoryModal();
      window.flashMessage(response.data.resultStatus.message);

    } catch (error) {
      handleError(error);
    }
  }

  async function handleCategoryUpdateFormOfKey(i) {
    if (categories[i].categoryName) {
      try {
        const data = {
          categoryName: categories[i].categoryName
        };
        const response = await RestService.PutRequest(UrlResolverService.resolve(
          ArticleEndpoints.UpdateArticleCategory,
          { categoryId: categories[i].id }
        ), data);
    
        window.flashMessage(response.data.resultStatus.message);
      } catch (error) {
        handleError(error,  [
          { stringToken: "[categoryName]", message: "Category name already exists." }
        ]);
      }
    }
  }

  function renderCategories() {
    return categories.map((category, i) => (
      <tr key={i} >
        <td className="border-0">
          <Row form>
            <Col className="form-group">
              <FormInput 
              required type="text"
              value={category.categoryName} 
              onChange={(e) => {handleCategoryInputOnKey(i, e)}} 
              invalid={category.categoryName.length === 0}
              />
              <FormFeedback>Cannot be empty</FormFeedback>
            </Col>
          </Row>
        </td>
        { renderCategoryActions(i, category.id) }
      </tr>
    ));
  }
  
  function renderCategoryActions(i, categoryId) {
    // Cannot Soft Delete Category ID 1 - 3
    if (categoryId >= 1 && categoryId <=3 ) {
      return (
        <td className="border-0">
          <Button type="submit" theme="primary" 
          onClick={async () => { await handleCategoryUpdateFormOfKey(i); }} 
          style={{ marginRight: "15px" }}> 
            Update 
          </Button>
        </td>
      );
    } else {
      return (
        <td className="border-0">
          <Button type="submit" theme="primary" 
          onClick={async () => { await handleCategoryUpdateFormOfKey(i); }} 
          style={{ marginRight: "15px" }}> 
            Update 
          </Button>
          <Button theme="danger" onClick={() => { showDeleteCategoryModal(categoryId) }}>Delete</Button>
        </td>
      );
    }
  }

  return(
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header pt-4 pb-2">
        <PageTitle sm="4" title="Article Categories" subtitle="Article" className="text-sm-left" />
      </Row>
      <div className="mb-2"><Link to="/articles"> &#8636; Go Back</Link></div>

      <Row className="justify-content-center">
        <Col md={8}>
          <Card small className="mb-4 ">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Update Categories</h6>
            </CardHeader>

            <CardBody style = {{ padding: 0 }}>
              <Row className="justify-content-center">
                <Col>
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Article Category Name
                        </th>
                        <th scope="col" className="border-0">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderCategories()}
                      <tr>
                        <td className="border-0">
                          <InputGroup className="ml-auto">
                              <FormInput placeholder="New category" value={newCategory} onChange={(e) => {setNewCategory(e.target.value)}} invalid={newCategoryInvalid}/>
                              <InputGroupAddon type="append">
                              <Button theme="white" className="px-2" onClick={handleNewCategoryForm}>
                                  <i className="material-icons">add</i>
                              </Button>
                              </InputGroupAddon>
                              <FormFeedback>Please input category name.</FormFeedback>
                          </InputGroup>
                        </td>
                        <td className="border-0"></td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal open={openDeleteCategoryModal} toggle={hideDeleteCategoryModal}>
          <ModalHeader style={{display:'block'}}>
              Delete Article
              <span className="float-right p-1" style={{cursor:'pointer'}} onClick={hideDeleteCategoryModal}>X</span>
          </ModalHeader>
          <ModalBody>
              <Row>
                <div className="mb-4">Are you sure you want to delete this article category?</div>
              </Row>
              <Row>
                <Col md={6}>
                  <Button block style={{fontSize:14}} theme="primary" onClick={handleDeleteCategory}>Delete</Button>
                </Col>
                <Col md={6}>
                  <Button block style={{fontSize:14}} theme="secondary" onClick={hideDeleteCategoryModal}>Cancel</Button>
                </Col>
              </Row>
          </ModalBody>
      </Modal>
    </Container>
  );

}


export default ArticleCategories;