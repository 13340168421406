const resolve = (url, params) => {

  const keys = Object.keys(params);
  for(let i = 0 ; i < keys.length ; i++ ) {
    let token = "{"+keys[i]+"}";
    const value = params[keys[i]];
    url = url.replace(token, value);
  }
  
  return url;
};

export default {
  resolve
};