import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const Range = (from, to, step =1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const Pagination = ({ totalPages = 0, pageNeighbors = 0, onPageChanged}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const generatePageNumbers = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = (pageNeighbors * 2) + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbors);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors);

      let pages = Range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = (totalPages - endPage) > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = Range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = Range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }
    return Range(1, totalPages);
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [totalPages]);

  const gotoPage = page => {
    const newCurrentPage = Math.max(0, Math.min(page, totalPages));
    setCurrentPage(newCurrentPage);
    onPageChanged(newCurrentPage);
  }

  const handleClick = page => evt => {
    evt.preventDefault();
    gotoPage(page);
  }

  const handleMoveLeft = evt => {
    evt.preventDefault();
    gotoPage(currentPage - 1);
  }

  const handleMoveRight = evt => {
    evt.preventDefault();
    gotoPage(currentPage + 1);
  }

  const pages= generatePageNumbers();

  return (totalPages === 0 ? null:
    <ul className="pagination justify-content-center pagination-lg mt-4">
        {pages.map((page, i) => {
          if (page === LEFT_PAGE) return (
            <li key={i} className="page-item border-right ">
              <a className="page-link" href="#" aria-label="Previous" onClick={handleMoveLeft}>
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </a>
            </li>
          );

          if (page === RIGHT_PAGE) return (
            <li key={i} className="page-item border-right ">
              <a className="page-link" href="#" aria-label="Next" onClick={handleMoveRight}>
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          );

          return (
            <li key={i} className={`page-item${ currentPage === page ? ' active' : ''}`}>
              <a className="page-link" href="#" onClick={ handleClick(page) }>
                { page }
              </a>
            </li>
          );
        })}
    </ul>
  );
};

// class Pagination extends React.Component {

//   constructor(props) {
//     super(props);
//     const { totalPages = 0, pageNeighbors = 0 } = props;

//     this.pageNeighbors = (typeof pageNeighbors === 'number') ? Math.max(0, Math.min(pageNeighbors, 2)) : 0;
//     this.totalPages = (typeof totalPages === 'number') ? totalPages: 0;

//     this.state = {
//       currentPage: 1
//     }
//   }  

//   componentDidMount() {
//     const currentPage = Math.max(0, Math.min(1, this.totalPages));
//     this.setState({ currentPage });
//   }

// const gotoPage = page => {
//   const { onPageChanged = f => f } = this.props;

//   const currentPage = Math.max(0, Math.min(page, this.totalPages));
  
//   this.setState({ currentPage }, () => onPageChanged(currentPage));
// }

// const handleClick = page => evt => {
//   evt.preventDefault();
//   this.gotoPage(page);
// }

// const handleMoveLeft = evt => {
//   evt.preventDefault();
//   this.gotoPage(this.state.currentPage - (this.pageNeighbours * 2) - 1);
// }

// const handleMoveRight = evt => {
//   evt.preventDefault();
//   this.gotoPage(this.state.currentPage + (this.pageNeighbours * 2) + 1);
// }


//   render() {
//     if (this.totalPages === 0) return null;

//     const { currentPage } = this.state;
//     const pages = this.generatePageNumbers();

//     return(
//       <ul className="pagination justify-content-center pagination-lg mt-4">
//         {pages.map((page, i) => {
//           if (page === LEFT_PAGE) return (
//             <li key={i} className="page-item border-right ">
//               <a className="page-link" href="#" aria-label="Previous" onClick={this.handleMoveLeft}>
//                 <span aria-hidden="true">&laquo;</span>
//                 <span className="sr-only">Previous</span>
//               </a>
//             </li>
//           );

//           if (page === RIGHT_PAGE) return (
//             <li key={i} className="page-item border-right ">
//               <a className="page-link" href="#" aria-label="Next" onClick={this.handleMoveRight}>
//                 <span aria-hidden="true">&raquo;</span>
//                 <span className="sr-only">Next</span>
//               </a>
//             </li>
//           );

//           return (
//             <li key={i} className={`page-item${ currentPage === page ? ' active' : ''}`}>
//               <a className="page-link" href="#" onClick={ this.handleClick(page) }>
//                 { page }
//               </a>
//             </li>
//           );
//         })}
//       </ul>
//     );
//   }

// }

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  pageNeighbors: PropTypes.number,
  onPageChanged: PropTypes.func
};

export default Pagination;