export default {
  CreateUser: "/admin/user/new",
  GetUserDetails: "/admin/user/{userId}",
  UpdateUser: "/admin/user/{userId}/update",
  ChangePassword: "/admin/user/{userId}/password/reset",
  PaginateUsers: "/admin/user/paginate?p={p}&n={n}",
  PaginateUserSearches: "/admin/user/paginate?p={p}&n={n}&q={q}",
  GetAllUserPermissions: "/admin/role/all",
  PaginateUsersByBirthday: "/admin/user/birthday/paginate?p={p}&n={n}",
  BatchInsertUsers: "/admin/user/new/batch",
  // SendWelcomeEmails: "/admin/user/welcome-email",
};