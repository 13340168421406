import React,{useState, useEffect} from "react";
import {
  Container,
  Card,CardHeader,CardBody,
  Row,Col,
  Form,FormFeedback,
  Button,
  Modal,ModalBody,ModalHeader,
  Collapse
} from "shards-react";
import {Link} from "react-router-dom";
import PageTitle from "../components/common/PageTitle";

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import UserEndpoints from "../data/api-endpoints/user";

function BatchInsertUsers() {
  const [csvFile, setCsvFile] = useState(null);
  const [csvFileName, setCsvFileName] = useState("Please Select a File");

  const [dbErrorMessages, setDbErrorMessages] = useState([]);
  const [invalidValueMessages, setInvalidValueMessages] = useState([]);
  const [miscMessages, setMiscMessages] = useState([]);

  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const [UploadButtonMessage, setUploadButtonMessage] = useState("Upload");
 
  // wait, loading, complete
  const [processState, setProcessState] = useState("wait");

  const [dbErrorLogOpen, setDbErrorLogOpen] = useState(false);
  const [miscLogOpen, setMiscLogOpen] = useState(false);
  const [invalidValueLogOpen, setInvalidValueLogOpen] = useState(false);

  function handleCsvFileInput(e) {
    setCsvFile(e.target.files[0]); 
    setCsvFileName(e.target.files[0].name);
  }

  async function handleCsvSubmit(e) {
    buttonIsDisabled(true);
    setProcessState("loading");
    e.preventDefault();

    const data = {
      file: csvFile
    };

    try {
      const response = await RestService.FormData.PostRequest(
        UserEndpoints.BatchInsertUsers,
        data
      );
  

      const dbErrors = [];
      const invalidValues = [];
      const misc = [];

      for(let i = 0; i < response.data.messages.length; i++) {
        const message = response.data.messages[i];

        if (message.messageType == "Invalid Value") {
          invalidValues.push(message);
        } else if (message.messageType == "DB Error") {
          dbErrors.push(message);
        } else  {
          misc.push(message);
        } 
      };

      setDbErrorMessages(dbErrors);
      setInvalidValueMessages(invalidValues);
      setMiscMessages(misc);

      window.flashMessage(response.data.resultStatus.message);
      buttonIsDisabled(false);
      setProcessState("complete");
    } catch (error) {
      handleError(error);
      setProcessState("wait");
      buttonIsDisabled(false);
    }
  }

  function buttonIsDisabled(disabled) {
    setUploadButtonDisabled(disabled);
    if (disabled) {
      setUploadButtonMessage("Processing...");
    } else {
      setUploadButtonMessage("Upload");
    }
  }

  function renderDbErrorMessages() { 
    return dbErrorMessages.map((message, i) => (
      <tr key={i}>
        <td>{message.key}</td>
        <td>{message.messageType}</td>
        <td>{message.message}</td>
      </tr>
    ));
  }

  function renderInvalidValueMessages() { 
    return invalidValueMessages.map((message, i) => (
      <tr key={i}>
        <td>{message.key}</td>
        <td>{message.messageType}</td>
        <td>{message.message}</td>
      </tr>
    ));
  }

  function renderMiscMessages() { 
    return miscMessages.map((message, i) => (
      <tr key={i}>
        <td>{message.key}</td>
        <td>{message.messageType}</td>
        <td>{message.message}</td>
      </tr>
    ));
  }

  function toggleDbErrorLog() {
    setDbErrorLogOpen(!dbErrorLogOpen);
  }

  function toggleInvalidValueLog() {
    setInvalidValueLogOpen(!invalidValueLogOpen);
  }

  function toggleMiscLog() {
    setMiscLogOpen(!miscLogOpen);
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header pt-4 pb-2">
        <PageTitle sm="4" title="Batch Upload Members" subtitle="Member" className="text-sm-left" />
      </Row>
      <div className="mb-2"><Link to="/accounts"> &#8636; Go Back</Link></div>

      <Row >
        <Col md={12}>
          <Card small className="mb-4 ">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Upload Member Data CSV</h6>
            </CardHeader>

            <CardBody>
              <Form onSubmit={handleCsvSubmit}>
                <Row form>
                  <Col md={4} className="form-group">
                    {/* <label>Upload CSV File</label> */}
                    <div className="custom-file">
                      <input required type="file" accept="text/csv" className="custom-file-input" onChange={handleCsvFileInput} />
                      <label className="custom-file-label">
                        {csvFileName}
                      </label>
                    </div>
                  </Col>
                  <Col md={2}>
                    <Button disabled={uploadButtonDisabled} block style={{fontSize:14}} type="submit">{UploadButtonMessage}</Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>

          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{display: (processState === "loading" ? "flex": "none") }}>
        <div>Processing data...</div>
      </Row>

      <Row style={{display: (processState === "complete" ? "flex": "none") }}>
        <Col md={12}>
          <Card small className="mb-4 ">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Batch upload alert</h6>
            </CardHeader>

            <CardBody>
              <Row>
                <Col>
                  <Button block style={{fontSize:14}} onClick={toggleDbErrorLog}>Database errors</Button>
                </Col>
                <Col>
                  <Button block style={{fontSize:14}} onClick={toggleInvalidValueLog}>Invalid value errors</Button>
                </Col>
                <Col>
                  <Button block style={{fontSize:14}} onClick={toggleMiscLog}>Others</Button>
                </Col>
              </Row>

              <Collapse open={dbErrorLogOpen}>
                <Row>
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Key (name/username)
                        </th>
                        <th scope="col" className="border-0">
                          Type
                        </th>
                        <th scope="col" className="border-0">
                          Message
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderDbErrorMessages()}
                    </tbody>
                  </table>
                </Row>
              </Collapse>

              <Collapse open={invalidValueLogOpen}>
                <Row>
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Key (name/username)
                        </th>
                        <th scope="col" className="border-0">
                          Type
                        </th>
                        <th scope="col" className="border-0">
                          Message
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderInvalidValueMessages()}
                    </tbody>
                  </table>
                </Row>
              </Collapse>

              <Collapse open={miscLogOpen}>
                <Row>
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Key (name/username)
                        </th>
                        <th scope="col" className="border-0">
                          Type
                        </th>
                        <th scope="col" className="border-0">
                          Message
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderMiscMessages()}
                    </tbody>
                  </table>
                </Row>
              </Collapse>

            </CardBody>
          </Card>
        </Col>
      </Row>
        
    </Container>
  );
}

export default BatchInsertUsers;