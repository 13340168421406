import React,{useState,useEffect} from "react";
import {
    Container,
    Card,CardHeader,CardBody,
    Row,Col,
    ListGroup,ListGroupItem,
    Form,FormInput,FormRadio,FormSelect,FormFeedback,
    InputGroup,InputGroupAddon,
    Button,
  } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {Link, Redirect} from "react-router-dom";
import AsyncSelect from 'react-select/async';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import "../assets/quill.css";

import base64Encode from "../services/ImageBase64EncoderService";

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import ArticleEndpoints from "../data/api-endpoints/article";
import CompanyEndpoints from "../data/api-endpoints/company";
import UrlResolverService from "../services/UrlResolverService";

const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
  ],
};

const editorStyle = {minHeight:450}

const placeholderImage = "https://via.placeholder.com/200x150";


function newArticles(){

  const visibilityOptions = [
    { value: "public", label: "Public" },
    { value: "free_member", label: "Regular Member and Above" },
    { value: "paid_member", label: "Premium Member Only" }
  ];

  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");

  const [selectedCompanyOption, setSelectedCompanyOption] = useState(null); // value is object {value: , label: }
  const [categoryId, setCategoryId] = useState(null);
  const [accessControlType, setAccessControlType] = useState("public");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [maxContentPreviewLength, setMaxContentPreviewLength] = useState(50);

  const [thumbnailImg, setThumbnailImg] = useState(null);
  const [thumbnailFileName, setthumbnailFileName] = useState("Select an Image");
  const [placeholderThumbnailImg, setPlaceholderThumbnailImg] = useState(placeholderImage);

  const [newCategoryInvalid, setNewCategoryInvalid] = useState(false);

  const [redirect, setRedirect] = useState(null);
  const [redirectTo, setRedirectTo] = useState("/");

  const [createArticleButtonDisabled, setCreateArticleButtonDisabled] = useState(false);
  const [createArticleButtonMessage, setCreateArticleButtonMessage] = useState("Continue");

  let timeout = null;

  async function fetchArticleCategories() {
    try {
      const response = await RestService.GetRequest(
        ArticleEndpoints.GetArticleCategories
      );
      setCategories(response.data.categories);
      setCategoryId(response.data.categories[0].id);
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    fetchArticleCategories();
  },[]);


  const mapCompanyNames = (results) => {
    return results.map(result => ({
      value: result.id,
      label: result.companyName
    }));
  };

  function fetchCompanyNames(inputValue, callback) {
    timeout = setTimeout(() => {
      RestService.GetRequest(UrlResolverService.resolve(
        CompanyEndpoints.SearchCompanyName,
        { q: inputValue }
      )).then(response => {

        callback(mapCompanyNames(response.data.results));

      }).catch(e => {
        handleError(e);
      });
    }, 1000);
  }

  function handleCompanyInputChange(input) {
    if (timeout != null) {
      clearTimeout(timeout);
    } 

    const inputValue = input.replace(/[^a-zA-Z0-9 ]/g, '');
    return inputValue;
  }

  function handleThumbnailInput(e) {
    setThumbnailImg(e.target.files[0]); 
    setthumbnailFileName(e.target.files[0].name);
    setPlaceholderThumbnailImg(URL.createObjectURL(e.target.files[0]));
  }

  async function handleFormSubmit(e) {
    createArticleButtonIsDisabled(true);
    e.preventDefault();
    if (title) {
      try {
        const data = {
          categoryId,
          promotedCompanyId: (selectedCompanyOption? selectedCompanyOption.value: null),
          title,
          content,
          maxContentPreviewLength,
          accessControlType,
          isActive: false,
          thumbnailImg: (thumbnailImg? await base64Encode(thumbnailImg, 960, 540): null),
        };

        const response = await RestService.PostRequest(
          ArticleEndpoints.CreateArticle,
          data
        );

        createArticleButtonIsDisabled(false);
        window.flashMessage("Article draft successfully created.");
        setRedirectTo("/articles/"+response.data.id);
        setRedirect(true);
      } catch (e) {
        handleError(e, [
          { stringToken: "[title]", message: "Title already exists or is too long." }
        ]);

        createArticleButtonIsDisabled(false);
      }
    }
  }

  function createArticleButtonIsDisabled(disabled) {
    setCreateArticleButtonDisabled(disabled);
    if (disabled) {
      setCreateArticleButtonMessage("Saving...");
    } else {
      setCreateArticleButtonMessage("Continue");
    }
  }

  async function handleNewCategoryForm(e) {
    e.preventDefault();
    if (newCategory) {
      setNewCategoryInvalid(false);
      try {
        const response = await RestService.PostRequest(
          ArticleEndpoints.CreateArticleCategory,
          { categoryName: newCategory }
        );

        const data = response.data;

        categories.push({
          id: data.id,
          categoryName: data.categoryName,
          gmtCreate: data.gmtCreate,
          gmtModified: data.gmtModified
        });
        
        setNewCategory("");
        window.flashMessage(response.data.resultStatus.message);
      } catch (e) {
        handleError(e, [
          { stringToken: "[categoryName]", message: "Category name already exists." }
        ]);
      }
    } else {
      setNewCategoryInvalid(true);
    }
  }

  function renderVisibilityOptions() {
    return visibilityOptions.map((option, i) => (
      <option key={i} value= {option.value}>{option.label}</option>
    ));
  }

  function renderCategories() {
    return categories.map((category, i) => (
        <FormRadio key={i} name={category.categoryName} onChange={()=> { setCategoryId(category.id) }} checked={categoryId === category.id} >{category.categoryName}</FormRadio>
    ));
  }

  function renderRedirect() {
    if (redirect) return <Redirect to={redirectTo}/>;
  }

return (
    <Container fluid className="main-content-container px-4 pb-4">
      {renderRedirect()}
    {/* Page Header */}
    <Row noGutters className="page-header pt-4 pb-2">
        <PageTitle sm="4" title="New Article" subtitle="Article" className="text-sm-left" />
    </Row>
    <div className="mb-2"><Link to="/articles"> &#8636; Go Back</Link></div>

    <Form className="add-new-post" onSubmit={handleFormSubmit}>
    <Row>
      {/* Editor */}
      <Col md="8">
        <Card small className="mb-3">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Article Information</h6>
            </CardHeader>
            <CardBody >
              {/* <Form className="add-new-post"> */}
                <Row form>
                  <Col md={6} className="form-group">
                    <label className="mr-3">Visibility</label>
                    <FormSelect value={accessControlType} onChange={(e) => { setAccessControlType(e.target.value) }}>
                        {renderVisibilityOptions()}
                    </FormSelect>
                    <FormFeedback>Please select a role.</FormFeedback>
                  </Col>
                </Row>

                <Row form>
                  <Col md={12} className="form-group" >
                    <label>Article Title <span style={{color: "red"}}>*</span></label>
                    <FormInput required size="lg" value={title} onChange={(e) => { setTitle(e.target.value) }}/>
                    <FormFeedback></FormFeedback>
                  </Col>
                </Row>

                <Row form>
                  <Col md={6} className="form-group">
                    <label>Thumbnail Image</label>
                    <div className="custom-file mb-3">
                        <input type="file" accept="image/*" className="custom-file-input" onChange={handleThumbnailInput} />
                        <label className="custom-file-label">
                          {thumbnailFileName}
                        </label>
                    </div>
                  </Col>
                  <Col md={6} className="justify-content-center">
                    <div className="mb-3 d-flex justify-content-center">
                        <img
                        src={placeholderThumbnailImg}
                        style={{objectFit: "contain"}}
                        width="200"
                        height="150"
                        alt="img"
                        />
                    </div>
                  </Col>
                </Row>
            
              {/* </Form> */}
            </CardBody>
        </Card>     

        <Card small className="mb-3">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Article Content</h6>
            </CardHeader>
            <CardBody>
                <Row form>
                  <Col md={12} className="form-group" >
                    <ReactQuill 
                      style={editorStyle} 
                      className="add-new-post__editor mb-1" 
                      modules={modules} 
                      value={content}
                      onChange={(value) => {setContent(value)}}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md={12} className="form-group" >
                    <label>Limit content preview to:</label>
                  </Col>
                  <Col md={2} className="form-group" >
                    <FormInput required type="number" value={maxContentPreviewLength} onChange={(e) => { setMaxContentPreviewLength(e.target.value) }}/>
                    <FormFeedback></FormFeedback>
                  </Col>
                  <Col md={10} className="form-group">
                    <div>words</div>
                  </Col>
                </Row>
            </CardBody>
        </Card>

      </Col>

      {/* Sidebar Widgets */}
      <Col md="4">
        <Card small className="mb-3">
            <CardHeader className="border-bottom">
              <h6 className="m-0 text-warning">Categories</h6>
            </CardHeader>
            <CardBody className="p-0">
                <ListGroup flush>
                    <ListGroupItem className="px-3 pb-2">
                      {renderCategories()}
                    </ListGroupItem>

                    <ListGroupItem className="d-flex px-3">
                        <InputGroup className="ml-auto">
                            <FormInput placeholder="New category" value={newCategory} onChange={(e) => {setNewCategory(e.target.value)}} invalid={newCategoryInvalid}/>
                            <InputGroupAddon type="append">
                            <Button theme="white" className="px-2" onClick={handleNewCategoryForm}>
                                <i className="material-icons">add</i>
                            </Button>
                            </InputGroupAddon>
                            <FormFeedback>Please input category name.</FormFeedback>
                        </InputGroup>
                    </ListGroupItem>
                </ListGroup>
            </CardBody>
        </Card>

        <Card small className="mb-3">
            <CardHeader className="border-bottom">
            <h6 className="m-0 text-primary">Promote a Partner</h6>
            </CardHeader>
            <CardBody className="p-0">
                <ListGroup flush>
                    <ListGroupItem className="p-3">
                        <AsyncSelect 
                          value = {selectedCompanyOption}
                          placeholder="Search" 
                          cacheOptions 
                          loadOptions={fetchCompanyNames}  
                          onInputChange={handleCompanyInputChange}
                          onChange={(option) => {setSelectedCompanyOption(option) }}
                          onFocus={() => { setSelectedCompanyOption(null) }}
                        />
                    </ListGroupItem>
                </ListGroup>
            </CardBody>
        </Card>

        <Card small className="mb-3">
            <CardHeader className="border-bottom">
            <h6 className="m-0 text-success">Next Step</h6>
            </CardHeader>
            <CardBody >
              <div>
                <Button disabled= {createArticleButtonDisabled} block style={{fontSize:14}} type="submit">{createArticleButtonMessage}</Button>
              </div>
            </CardBody>
        </Card>
      </Col>
    </Row>
    </Form>
  </Container>
 );
}

export default newArticles;
