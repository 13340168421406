import React,{useState,useEffect} from "react";
import { Container, Row, Col,
  Card,CardHeader,CardBody,CardImg,
  Button,
  FormSelect,
} from "shards-react";

import Pagination from "../components/common/Pagination";
import PageTitle from "../components/common/PageTitle";
import {Link} from 'react-router-dom';

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import ArticleEndpoints from "../data/api-endpoints/article";
import UrlResolverService from "../services/UrlResolverService";

import moment from "moment";

const imageStyle= {height:100,objectFit:'cover'}

const placeholderImage = require("../images/placeholder/no_image_placeholder.png");

function Article() {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState([]);

  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [totalPages, setTotalPages] = useState(0);

  async function fetchArticleCategories() {
    try {
      const response = await RestService.GetRequest(
        ArticleEndpoints.GetArticleCategories
      );
      setCategories(response.data.categories);
    } catch (e) {
      handleError(e);
    }
  };

  async function fetchArticles() {
    try {
      const response = await RestService.GetRequest( UrlResolverService.resolve(
        ArticleEndpoints.PaginateArticles,
        { p: 1, n: itemsPerPage }
      ));
      setArticles(response.data.page.content);
      setTotalPages(response.data.page.totalPages);
    } catch (e) {
      handleError(e);
    }
  }

  useEffect(() => {
    fetchArticleCategories();
    fetchArticles();
  },[]);

  async function fetchArticlesByCategoryId(id) {
    try {
      const response = await RestService.GetRequest( UrlResolverService.resolve(
        ArticleEndpoints.PaginateArticlesByCategory,
        { p: 1, n: itemsPerPage, c: id }
      ));
      setArticles(response.data.page.content);
      setTotalPages(response.data.page.totalPages);
    } catch (e) {
      handleError(e);
    }
  }

  async function fetchArticleDrafts() {
    try {
      const response = await RestService.GetRequest( UrlResolverService.resolve(
        ArticleEndpoints.PaginateInactiveArticles,
        { p: 1, n: itemsPerPage }
      ));
      setArticles(response.data.page.content);
      setTotalPages(response.data.page.totalPages);
    } catch (e) {
      handleError(e);
    }
  }

  async function handleCategorySelectChange(id) {
    const newId = parseInt(id, 10);
    setSelectedCategory(newId);
    if (newId === -1) {
      fetchArticleDrafts();
    } 
    else if (newId === 0) {
      fetchArticles();
    } 
    else fetchArticlesByCategoryId(newId);
  }

  function renderArticleCategories() {
      return categories.map((category, i) => (
        <option key={i} value={category.id}>{category.categoryName}</option>
      ));
  }

  function renderArticles() {
    return articles.map((article, i) => (
      <Col md={3} key={i} >
        <Card className="mb-4">
          <CardImg top src={article.thumbnailImgUrl? article.thumbnailImgUrl: placeholderImage} style={imageStyle}/>
          <CardBody style={{padding:20}}>
            <div style={{fontSize:18}}> {article.title.length > 47? article.title.substring(0,47)+"...": article.title} </div>
            <div>
                <div className="text-muted"> {article.category.categoryName} </div>
            </div>
            <div>
                <div className="text-muted" style={{fontSize: 12}}> 
                  {moment(article.gmtCreate).format("MMM Do YYYY, h:mm a")} 
                </div>
            </div>
            <div className="mt-3">
              <Row>
                  <Col>
                    <Link to= {"/articles/"+article.id}>
                      <Button block >View</Button>
                    </Link>
                  </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    ));
  }

  async function handlePagination(page) {
    try {
      if (selectedCategory === -1) {
        const response = await RestService.GetRequest( UrlResolverService.resolve(
          ArticleEndpoints.PaginateInactiveArticles,
          { p: page, n: itemsPerPage }
        ));
        setArticles(response.data.page.content);
      }
      else if (selectedCategory === 0) {
        const response = await RestService.GetRequest( UrlResolverService.resolve(
          ArticleEndpoints.PaginateArticles,
          { p: page, n: itemsPerPage }
        ));
        setArticles(response.data.page.content);
      } else {
        const response = await RestService.GetRequest( UrlResolverService.resolve(
          ArticleEndpoints.PaginateArticlesByCategory,
          { p: page, n: itemsPerPage, c: selectedCategory }
        ));
        setArticles(response.data.page.content);
      }
    } catch (e) {
      handleError(e);
    }
  }
  
  return(
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Article List" subtitle="Article" className="text-sm-left" />
      </Row>

      <Row className="d-flex justify-content-end mb-2">
        <Col>
            <div>
              <Link to="/articles/new">
                <Button theme="success" style ={{fontSize:16}}className="float-right">Add New Article</Button>
              </Link>
            </div>
        </Col>
      </Row>

      <Row>
        <Col md="12">
            <Card>
                <CardHeader className="border-bottom">
                  <span className="mr-5">Filter by Category</span>
                  <Row>
                    <Col md={3}>
                    <FormSelect value={selectedCategory} onChange={(e) => { handleCategorySelectChange(e.target.value) }}>
                      <option value="0">All Published Articles</option>
                      { renderArticleCategories() }
                      <option value="-1">Drafts</option>
                    </FormSelect>
                    </Col>
                    <Col md={9}>
                    <div>
                      <Link to="/article-categories">
                        <Button theme="primary" style ={{fontSize:16}}className="float-right">Manage Article Categories</Button>
                      </Link>
                    </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    {renderArticles()}
                  </Row>
                  <nav style={{cursor:'pointer'}}>
                    <Pagination totalPages={totalPages} pageNeighbors={2} onPageChanged={handlePagination}/>
                  </nav>
                </CardBody>
            </Card>
        </Col>


      </Row>
  </Container>
  )
}

export default Article;
