import React,{useState} from "react";
import {
    Container,
    Card,CardHeader,
    Row,Col,
    ListGroup,ListGroupItem,
    Form,FormInput,FormFeedback,FormTextarea,
    Button,
  } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {Link, Redirect} from "react-router-dom";

import base64Encode from "../services/ImageBase64EncoderService";

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import CompanyEndpoints from "../data/api-endpoints/company";

// const placeholderImage = require("../images/placeholder/no_image_placeholder.png");
// const placeholderImage2 = "https://via.placeholder.com/200";

function newCompany() {
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyIndustry, setCompanyIndustry] = useState("");
  const [companyContactNo, setCompanyContactNo] = useState("");
  const [companyWebUrl, setCompanyWebUrl] = useState("");

  const [companyLogo, setCompanyLogo] = useState(null);
  const [logoFileName, setLogoFileName] = useState("Select an Image");
  const [placeholderImg, setPlaceholderImg] = useState("https://via.placeholder.com/200");
  
  const [logoOrderPriority, setLogoOrderPriority] = useState(99999);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonMessage, setButtonMessage] = useState("Submit");

  const [redirect, setRedirect] = useState(false);

  async function handleSubmitForm(e) {
    buttonIsDisabled(true);
    e.preventDefault();
    if (companyName !== "" && companyLogo !== null) {
      try {
        const base64 = await base64Encode(companyLogo, 400, 400);

        const data = {
          companyName,
          companyAddress,
          companyDescription,
          companyEmail,
          companyIndustry,
          companyContactNo,
          companyLogo: base64,
          logoOrderPriority,
          companyWebUrl
        };

        const response = await RestService.PostRequest(
          CompanyEndpoints.CreateCompany,
          data
        );
        buttonIsDisabled(false);
        window.flashMessage(response.data.resultStatus.message);
        setRedirect(true);
      } catch(e) {
        buttonIsDisabled(false);
        handleError(e);
      }
    }
  }

  function buttonIsDisabled(disabled) {
    setButtonDisabled(disabled);
    if (disabled) {
      setButtonMessage("Submitting...");
    } else {
      setButtonMessage("Submit");
    }
  }

  function handleFileInput(e) {
    setCompanyLogo(e.target.files[0]); 
    setLogoFileName(e.target.files[0].name);
    setPlaceholderImg(URL.createObjectURL(e.target.files[0]));
  }

  const renderRedirect = () => {
    if (redirect) return <Redirect to="/partners"/>;
  }

  return (
      <Container fluid className="main-content-container px-4 pb-4">
        {renderRedirect()}
      {/* Page Header */}
      <Row noGutters className="page-header pt-4 pb-2">
          <PageTitle sm="4" title="New Partner Company" subtitle="Partner" className="text-sm-left" />
      </Row>
      <div className="mb-2"><Link to="/partners"> &#8636; Go Back</Link></div>

      <Row className="justify-content-center">
          <Col lg={8}>
              <Card small className="mb-4 ">
                  <CardHeader className="border-bottom">
                  <h6 className="m-0">New Partner Company</h6>
                  </CardHeader>

                  <ListGroup flush>
                      <ListGroupItem className="p-3">
                      <Form onSubmit = {handleSubmitForm}>
                          <Row form>
                              <Col md={6} className="form-group">
                                  <label>Partner Name</label><label style={{color: "red"}}>*</label>
                                  <FormInput value={companyName} onChange={(e) => {setCompanyName(e.target.value)}} required/>
                                  <FormFeedback></FormFeedback>
                              </Col>
                          </Row>
                          <Row form>
                              <Col md={12} className="form-group">
                                  <label>Partner Short Description</label>
                                  <FormTextarea rows="3" value ={companyDescription}  onChange={(e) => {setCompanyDescription(e.target.value)}} />
                              </Col>
                          </Row>

                          <Row form>
                              <Col md={12} className="form-group">
                                  <label>Partner Address</label>
                                  <FormInput value = {companyAddress}  onChange={(e) => {setCompanyAddress(e.target.value)}} />
                              </Col>
                          </Row>

                          <Row form>
                              <Col md={6} className="form-group">
                                  <label>Partner Email</label>
                                  <FormInput type="email" value = {companyEmail}  onChange={(e) => {setCompanyEmail(e.target.value)}} />
                              </Col>
                              <Col md={6} className="form-group">
                                  <label>Partner Industry</label>
                                  <FormInput value = {companyIndustry}  onChange={(e) => {setCompanyIndustry(e.target.value)}} />
                              </Col>
                          </Row>
                          <Row form>
                              <Col md={6} className="form-group">
                                  <label>Partner Contact No.</label>
                                  <FormInput value = {companyContactNo}  onChange={(e) => {setCompanyContactNo(e.target.value)}}/>
                              </Col>
                              <Col md={6} className="form-group">
                                  <label>Partner Web URL</label>
                                  <FormInput value = {companyWebUrl}  onChange={(e) => {setCompanyWebUrl(e.target.value)}}/>
                              </Col>
                          </Row>
                          <Row form>
                            <Col md={6} className="form-group">
                              <label>Order Priority</label><label style={{color: "red"}}>*</label>
                              <FormInput type="number" value={logoOrderPriority} onChange={(e) => {setLogoOrderPriority(e.target.value)}} required/>
                              <FormFeedback></FormFeedback>
                            </Col>

                            <Col md={6} className="form-group">
                                  <label>Logo</label><label style={{color: "red"}}>*</label>
                                  <div className="custom-file mb-3">
                                      <input required type="file" accept="image/*" className="custom-file-input" onChange={handleFileInput} />
                                      <label className="custom-file-label">
                                      {logoFileName}
                                      </label>
                                  </div>
                              </Col>
                          </Row>
                          <Row form>
                            <Col md={12}>
                              <div className="mb-3 d-flex justify-content-center">
                                  <img
                                  src={placeholderImg}
                                  style={{objectFit: "contain"}}
                                  width="200"
                                  height="200"
                                  alt="img"
                                  />
                              </div>
                            </Col>
                          </Row>

                          <div className="mb-3"><span style={{color: "red"}}>*</span>Required Field</div>
                          <div className="d-flex justify-content-center mb-2">
                              <Button disabled={buttonDisabled} block style={{fontSize:16}} type="submit">{buttonMessage}</Button>
                          </div>
                      </Form>
                  </ListGroupItem>
                  </ListGroup>
              
              </Card>
          </Col>
      </Row>

    </Container>
  );
}

export default newCompany;
