import TokenService from "./TokenCookieService";

import RestService from "./RestService";
import AuthEndpoints from "../data/api-endpoints/auth";
import handleError from "./ErrorHandlerService";

async function getAuthenticationInfo() {
  try {
    const response = await RestService.GetRequest(AuthEndpoints.VerifyToken);
    return response.data;
  } catch (e) {
    handleError(e);
    return {
      isSignedIn: false
    };
    
  }
}

async function authenticate(email, password) {
  const response = await RestService.PostRequest(
    AuthEndpoints.Login,
    {email, password}
  );
    // TODO: Cookie is set in backend (HttpOnly)
  // TokenService.UpdateTokenCookie(response.data.token);
}

async function authenticateWithRoleAdmin(email, password) {
  const response = await RestService.PostRequest(
    AuthEndpoints.Login,
    {email, password}
  );

  if (response.data.role !== "admin") {
    throw new Error("Insufficient privilege.");
  } 
  // TODO: Cookie is set in backend (HttpOnly)
  // else {
  //   TokenService.UpdateTokenCookie(response.data.token);
  // }
}

async function logout() {
  // TODO: Cookie is set in backend (HttpOnly)
  // TokenService.RemoveTokenCookie();

  await RestService.PostRequest(AuthEndpoints.Logout);
}

export default {
  getAuthenticationInfo,
  authenticate,
  logout,
  authenticateWithRoleAdmin
};