import React,{useState,useEffect} from "react";
import {
    Container,
    Card,CardHeader,CardBody,
    Row,Col,
    ListGroup,ListGroupItem,
    Modal,ModalHeader,ModalBody,
    Form,FormInput,FormRadio,FormSelect,FormFeedback,FormTextarea,
    InputGroup,InputGroupAddon,
    Button,
  } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {Link, Redirect, useParams} from "react-router-dom";
import AsyncSelect from 'react-select/async';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import "../assets/quill.css";

import base64Encode from "../services/ImageBase64EncoderService";

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import ArticleEndpoints from "../data/api-endpoints/article";
import CompanyEndpoints from "../data/api-endpoints/company";
import UrlResolverService from "../services/UrlResolverService";

const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
  ],
};

const editorStyle = {minHeight:450}

const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

const placeholderImage = require("../images/placeholder/no_image_placeholder.png");
const placeholderMediaImage = "https://via.placeholder.com/200x150";

function newArticles(){

  const {articleId} = useParams();

  const visibilityOptions = [
    { value: "public", label: "Public" },
    { value: "free_member", label: "Regular Member and Above" },
    { value: "paid_member", label: "Premium Member Only" }
  ];

  const mediaTypeOptions = [
    { value: "image", label: "Image" },
    { value: "video", label: "Video" }
  ];

  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [newCategoryInvalid, setNewCategoryInvalid] = useState(false);

  const [selectedCompanyOption, setSelectedCompanyOption] = useState(null); // value is object {value: , label: }
  const [categoryId, setCategoryId] = useState(null);
  const [accessControlType, setAccessControlType] = useState("public");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [articleMedia, setArticleMedia] = useState([]);

  const [contentPreview, setContentPreview] = useState("");
  const [maxContentPreviewLength, setMaxContentPreviewLength] = useState(50);

  const [thumbnailImg, setThumbnailImg] = useState(null);
  const [thumbnailFileName, setThumbnailFileName] = useState("Select an Image");
  const [placeholderThumbnailImg, setPlaceholderThumbnailImg] = useState(placeholderImage);

  let timeout = null;

  const [openDeleteArticleModal, setOpenDeleteArticleModal] = useState(false);

  const [openDeleteMediaModal, setOpenDeleteMediaModal] = useState(false);
  const [mediaIdToDelete, setMediaIdToDelete] = useState(null);

  const [openAddMediaModal, setOpenAddMediaModal] = useState(false);

  const [newMediaType, setNewMediaType] = useState("image");
  const [newVideoUrl, setNewVideoUrl] = useState("");
  const [newMediaAccessControlType, setNewMediaAccessControlType] = useState("public");

  const [newMediaImg, setNewMediaImg] = useState(null);
  const [newMediaFileName, setNewMediaFileName] = useState("Select an Image");
  const [placeholderMediaImg, setPlaceholderMediaImg] = useState(placeholderMediaImage);

  const [articleDeleteRedirect, setArticleDeleteRedirect] = useState(false);

  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false);
  const [updateButtonMessage, setUpdateButtonMessage] = useState("Update");

  const [publicationButtonDisabled, setPublicationButtonDisabled] = useState(false);
  const [publicationButtonMessage, setPublicationButtonMessage] = useState("Publish");

  const [prevCategoryId, setPrevCategoryId] = useState(null);
  const [prevTitle, setPrevTitle] = useState(null);
  const [prevAccessControlType, setPrevAccessControlType] = useState(null);

  async function fetchArticleCategories() {
    try {
      const response = await RestService.GetRequest(
        ArticleEndpoints.GetArticleCategories
      );
      setCategories(response.data.categories);
    } catch (e) {
      handleError(e);
    }
  };

  async function fetchArticleDetails() {
    try {
      const articleResponse = await RestService.GetRequest(UrlResolverService.resolve(
        ArticleEndpoints.GetArticleDetailsById,
        {articleId}
      ));

      const data = articleResponse.data;

      if (data.promotedCompanyId) {
        const companyResponse = await RestService.GetRequest(UrlResolverService.resolve(
          CompanyEndpoints.GetCompanyById,
          {companyId: data.promotedCompanyId}
        ));
        setSelectedCompanyOption({
          value: companyResponse.data.id,
          label: companyResponse.data.companyName
        });
      }
      setTitle(data.title);
      setPrevTitle(data.title);

      setCategoryId(data.category.id);
      setPrevCategoryId(data.category.id);

      setAccessControlType(data.accessControlType);
      setPrevAccessControlType(data.accessControlType);

      setContent(data.content);
      setContentPreview(data.contentPreview);
      setMaxContentPreviewLength(data.contentPreview.split(/\s+/).length);
      setIsActive(data.isActive);

      if (data.media.items) setArticleMedia(data.media.items);
      if (data.thumbnailImgUrl) setPlaceholderThumbnailImg(data.thumbnailImgUrl);

      setPublicationButtonMessage(data.isActive? "Unpublish": "Publish");
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    fetchArticleCategories();
    fetchArticleDetails();
  },[]);


  const mapCompanyNames = (results) => {
    return results.map(result => ({
      value: result.id,
      label: result.companyName
    }));
  };

  function fetchCompanyNames(inputValue, callback) {
    timeout = setTimeout(() => {
      RestService.GetRequest(UrlResolverService.resolve(
        CompanyEndpoints.SearchCompanyName,
        { q: inputValue }
      )).then(response => {

        callback(mapCompanyNames(response.data.results));

      }).catch(e => {
        handleError(e);
      });
    }, 1000);
  }

  function handleCompanyInputChange(input) {
    if (timeout != null) {
      clearTimeout(timeout);
    } 

    const inputValue = input.replace(/[^a-zA-Z0-9 ]/g, '');
    return inputValue;
  }

  function handleThumbnailInput(e) {
    setThumbnailImg(e.target.files[0]); 
    setThumbnailFileName(e.target.files[0].name);
    setPlaceholderThumbnailImg(URL.createObjectURL(e.target.files[0]));
  }

  async function handleFormSubmit() {
    
    if (title) {
      try {
        updateButtonIsDisabled(true);
        const data = {
          categoryId,
          promotedCompanyId: (selectedCompanyOption? selectedCompanyOption.value: null),
          title,
          content,
          maxContentPreviewLength,
          accessControlType,
          thumbnailImg: (thumbnailImg? await base64Encode(thumbnailImg, 960, 540): null)
        };

        const response = await RestService.PutRequest(
          UrlResolverService.resolve(
            ArticleEndpoints.UpdateArticle,
            {articleId}
          ), 
          data
        );

        setContentPreview(response.data.contentPreview);

        window.flashMessage(response.data.resultStatus.message);
        updateButtonIsDisabled(false);
      } catch (e) {
        handleError(e, [
          { stringToken: "[title]", message: "Title already exists or is too long." }
        ]);
        updateButtonIsDisabled(false);
      }
    }
  }

  function updateButtonIsDisabled(disabled) {
    setUpdateButtonDisabled(disabled);
    if (disabled) {
      setUpdateButtonMessage("Submitting...");
    } else {
      setUpdateButtonMessage("Update");
    }
  }

  async function handleArticlePublication() {
    try {
      publicationButtonIsDisabled(true);
      const data = {
        categoryId: prevCategoryId,
        title: prevTitle,
        accessControlType: prevAccessControlType,
        isActive: !isActive
      };
      
      await RestService.PutRequest(
        UrlResolverService.resolve(
          ArticleEndpoints.UpdateArticle,
          {articleId}
        ), 
        data
      );
      setIsActive(!isActive);
      window.flashMessage("Article successfully "+ (data.isActive? "published.": "unpublished."));
      publicationButtonIsDisabled(false, (data.isActive? "Unpublish": "Publish"));
    } catch (e) {
      handleError(e);
      publicationButtonIsDisabled(false);
    }
  }

  function publicationButtonIsDisabled(disabled, customMessage) {
    setPublicationButtonDisabled(disabled);
    if (disabled) {
      setPublicationButtonMessage("Publishing...");
    } else {
      if (customMessage) setPublicationButtonMessage(customMessage);
      else setPublicationButtonMessage(isActive? "Unpublish": "Publish");
    }
  }

  function showDeleteArticleModal() {
    setOpenDeleteArticleModal(true);
  }

  function hideDeleteArticleModal() {
    setOpenDeleteArticleModal(false);
  }

  function showDeleteMediaModal(mediaId) {
    setOpenDeleteMediaModal(true);
    setMediaIdToDelete(mediaId); 
  }

  function hideDeleteMediaModal() {
    setOpenDeleteMediaModal(false);
    setMediaIdToDelete(null); 
  }

  function toggleAddMediaModal() {
    setNewMediaType("image");
    setNewVideoUrl("");
    setNewMediaImg(null);
    setNewMediaFileName("Select an Image");
    setNewMediaAccessControlType("public");
    setPlaceholderMediaImg(placeholderMediaImage);
    setOpenAddMediaModal(!openAddMediaModal);
  }

  function handleNewMediaInput(e) {
    setNewMediaImg(e.target.files[0]); 
    setNewMediaFileName(e.target.files[0].name);
    setPlaceholderMediaImg(URL.createObjectURL(e.target.files[0]));
  }

  async function submitNewMediaForm(e) {
    e.preventDefault();
    try {
      const data = {
        mediaType: newMediaType,
        accessControlType: newMediaAccessControlType,
        isActive: true
      };

      if (newMediaType === "image") {
        if (newMediaImg) {
          data['media'] = await base64Encode(newMediaImg);
        }
      } else {
        if (newVideoUrl) {
          data['media'] = newVideoUrl;
        }
      }
      
      const response = await RestService.PostRequest(
        UrlResolverService.resolve(
          ArticleEndpoints.CreateArticleMedia,
          {articleId}
        ),
        data
      );

      const newData = response.data;

      articleMedia.push({
        id: newData.id,
        mediaType: newData.mediaType,
        mediaUrl: newData.mediaUrl,
        isActive: newData.isActive,
        accessControlType: newData.accessControlType,
        gmtCreate: newData.gmtCreate,
        gmtModified: newData.gmtModified
      });

      toggleAddMediaModal();
      window.flashMessage(response.data.resultStatus.message);
    } catch (e) {
      handleError(e);
    }
  }

  async function handleDeleteArticle() {
    try {
      const response = await RestService.DeleteRequest(UrlResolverService.resolve(
        ArticleEndpoints.DeleteArticle,
        { articleId }
      ));

      hideDeleteArticleModal();
      setArticleDeleteRedirect(true);
      window.flashMessage(response.data.resultStatus.message);
    } catch (e) {
      handleError(e);
    }
  }

  async function handleDeleteMedia() {
    try {
      const articleMediaId = mediaIdToDelete;
      const response = await RestService.DeleteRequest(UrlResolverService.resolve(
        ArticleEndpoints.DeleteArticleMedia,
        { articleMediaId }
      ));
      
      for (let i = 0; i < articleMedia.length; i++) {
        if (articleMedia[i].id === articleMediaId) {
          articleMedia.splice(i,1);
          break;
        }
      }

      hideDeleteMediaModal();
      window.flashMessage(response.data.resultStatus.message);
    } catch (e) {
      handleError(e);
    }
  }

  async function handleNewCategoryForm(e) {
    e.preventDefault();
    if (newCategory) {
      setNewCategoryInvalid(false);
      try {
        const response = await RestService.PostRequest(
          ArticleEndpoints.CreateArticleCategory,
          { categoryName: newCategory }
        );

        const data = response.data;

        categories.push({
          id: data.id,
          categoryName: data.categoryName,
          gmtCreate: data.gmtCreate,
          gmtModified: data.gmtModified
        });
        
        setNewCategory("");
        window.flashMessage(response.data.resultStatus.message);
      } catch (e) {
        handleError(e, [
          { stringToken: "[categoryName]", message: "Category name already exists." }
        ]);
      }
    } else {
      setNewCategoryInvalid(true);
    }
  }

  function renderVisibilityOptions() {
    return visibilityOptions.map((option, i) => (
      <option key={i} value= {option.value}>{option.label}</option>
    ));
  }

  function renderMediaTypeOptions() {
    return mediaTypeOptions.map((option, i) => (
      <option key={i} value= {option.value}>{option.label}</option>
    ));
  }

  function renderCategories() {
    return categories.map((category, i) => (
      <FormRadio key={i} name={category.categoryName} onChange={()=> { setCategoryId(category.id) }} checked={categoryId === category.id} >{category.categoryName}</FormRadio>
    ));
  }

  function renderArticleMedia() {
    return articleMedia.map((media, i) => {
      if (media.mediaType === "image") return(
        <tr key={i}>
          <td> {media.mediaType} </td>
          <td> <img src={media.mediaUrl} style={{objectFit: "contain"}} width="100" height="75" alt={"image-"+(i+1)} /> </td>
          <td> {
            media.accessControlType === "free_member"? "Free member and above":
            media.accessControlType === "paid_member"? "Premium member only": "Public"
          } </td>
          <td> 
            <a href={media.mediaUrl} style={{ marginRight: "15px" }}> 
              <Button theme="success">View</Button>
            </a>
            <Button theme="danger" onClick={() => {showDeleteMediaModal(media.id)}}>Delete</Button>
          </td>
        </tr>
      );
      else return (
        <tr key={i}>
          <td> {media.mediaType} </td>
          <td> {media.mediaUrl} </td>
          <td> {media.accessControlType} </td>
          <td> 
            <a href={media.mediaUrl} style={{ marginRight: "15px" }}> 
              <Button theme="success">View</Button>
            </a>
            <Button theme="danger" onClick={() => {showDeleteMediaModal(media.id)}}>Delete</Button>
          </td>
        </tr>
      );
      
    });
  }

  function renderArticleDeleteRedirect() {
    if (articleDeleteRedirect) return <Redirect to="/articles"/>;
  }

return (
    <Container fluid className="main-content-container px-4 pb-4">

    { renderArticleDeleteRedirect() }
    {/* Page Header */}
    <Row noGutters className="page-header pt-4 pb-2">
        <PageTitle sm="4" title="Article Details" subtitle="Article" className="text-sm-left" />
    </Row>
    <div className="mb-2"><Link to="/articles"> &#8636; Go Back</Link></div>

    
    <Row>
      <Col md="8">
          <Card small className="mb-3">
              <CardHeader className="border-bottom">
                <Row>
                  <Col md={8}>
                    <h6 className="m-0">Update Article Information</h6>
                  </Col>
                  <Col md={4}>
                    <Button block style={{fontSize:14}} theme="danger" onClick= {() =>{ showDeleteArticleModal() }}>Delete Article</Button>
                  </Col>
                </Row>
                
              </CardHeader>
              <CardBody >
                  <Row form>
                    <Col md={6} className="form-group">
                      <label className="mr-3">Visibility</label>
                      <FormSelect value={accessControlType} onChange={(e) => { setAccessControlType(e.target.value) }}>
                          {renderVisibilityOptions()}
                      </FormSelect>
                      <FormFeedback>Please select a role.</FormFeedback>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12} className="form-group" >
                      <label>Article Title <span style={{color: "red"}}>*</span> (Required)</label>
                      <FormInput required size="lg" value={title} onChange={(e) => { setTitle(e.target.value) }} invalid={title.length === 0}/>
                      <FormFeedback>Article Title is required.</FormFeedback>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={6}>
                      <Card small className="mb-3">
                          <CardHeader className="border-bottom">
                            <h6 className="m-0 text-warning">Article Category</h6>
                          </CardHeader>
                          <CardBody className="p-0">
                              <ListGroup flush>
                                  <ListGroupItem className="px-3 pb-2">
                                      {renderCategories()}
                                  </ListGroupItem>
                                  
                                  <ListGroupItem className="d-flex px-3">
                                      <InputGroup className="ml-auto">
                                          <FormInput placeholder="New category" value={newCategory} onChange={(e) => {setNewCategory(e.target.value)}} invalid={newCategoryInvalid}/>
                                          <InputGroupAddon type="append">
                                          <Button theme="white" className="px-2" onClick={handleNewCategoryForm}>
                                              <i className="material-icons">add</i>
                                          </Button>
                                          </InputGroupAddon>
                                      </InputGroup>
                                  </ListGroupItem>
                              </ListGroup>
                          </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card small className="mb-3">
                          <CardHeader className="border-bottom">
                          <h6 className="m-0 text-primary">Promoted Partner</h6>
                          </CardHeader>
                          <CardBody className="p-0">
                              <ListGroup flush>
                                  <ListGroupItem className="p-3">
                                      <AsyncSelect 
                                        value = {selectedCompanyOption}
                                        placeholder="Search" 
                                        cacheOptions 
                                        loadOptions={fetchCompanyNames}  
                                        onInputChange={handleCompanyInputChange}
                                        onChange={(option) => {setSelectedCompanyOption(option) }}
                                        onFocus={() => { setSelectedCompanyOption(null) }}
                                      />
                                  </ListGroupItem>
                              </ListGroup>
                          </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row form style={{marginBottom:"20px", marginTop:"20px" }}>
                    <Col md={6} className="form-group">
                      <label>Thumbnail Image</label>
                      <div className="custom-file">
                          <input type="file" accept="image/*" className="custom-file-input" onChange={handleThumbnailInput} />
                          <label className="custom-file-label">
                            {thumbnailFileName}
                          </label>
                      </div>
                    </Col>
                    <Col md={6} className="justify-content-center">
                      <div className="mb-3 d-flex justify-content-center">
                          <img
                          src={placeholderThumbnailImg}
                          style={{objectFit: "contain"}}
                          width="200"
                          height="150"
                          alt="img"
                          />
                      </div>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12} className="form-group" >
                      <label>Article Content</label>
                      <Row>
                        <Col md={12} className="form-group" >
                          <ReactQuill 
                            style={editorStyle} 
                            className="add-new-post__editor" 
                            modules={modules} 
                            value={content}
                            onChange={(value) => {setContent(value)}}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12} className="form-group" >
                      <label>Content Preview</label>
                      <Row>
                        <Col md={12} className="form-group" >
                          <FormTextarea rows="5" disabled value ={contentPreview}></FormTextarea>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12} className="form-group" >
                      <label>Limit content preview to:</label>
                    </Col>
                    <Col md={2} className="form-group" >
                      <FormInput required type="number" value={maxContentPreviewLength} onChange={(e) => { setMaxContentPreviewLength(e.target.value) }}/>
                      <FormFeedback></FormFeedback>
                    </Col>
                    <Col md={10} className="form-group">
                      <div>words</div>
                    </Col>
                  </Row>

                  <Row className="justify-content-center">
                    <Col md={6} className="mb-3">
                      <Button disabled={updateButtonDisabled} block style={{fontSize:14}} type="submit" theme="success" onClick={handleFormSubmit}>{updateButtonMessage}</Button>
                    </Col>
                  </Row>
              </CardBody>
          </Card>     

          <Card small className="mb-3">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Article Media</h6>
            </CardHeader>
            <CardBody>
                <Row className="mb-3">
                  <Col md={3}>
                    <Button block style={{fontSize:14}} onClick={toggleAddMediaModal}>Add Media</Button>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <table className="table mb-3">
                      <thead className="bg-light">
                          <tr>
                            <th scope="col" className="border-0">
                                Type
                            </th>
                            <th scope="col" className="border-0">
                                Preview
                            </th>
                            <th scope="col" className="border-0">
                                Visibility
                            </th>
                            <th scope="col" className="border-0">
                                Actions
                            </th>
                          </tr>
                      </thead>
                      <tbody>
                          {renderArticleMedia()}
                      </tbody>
                    </table>
                  </Col>
                </Row>
            </CardBody>
          </Card>
      </Col>

      <Col md="3" style={{ position: 'fixed', bottom: '5%', right: '2%'}}>
        <Card small className="mb-3">
            <CardHeader className="border-bottom">
              <h6 className="m-0 text-success">Publication</h6>
            </CardHeader>
            <CardBody >
              <div className="mb-3">
                {"This article is "+ (isActive? "published.": "not published.")}
              </div>
              <div>
                <Button disabled={publicationButtonDisabled} block style={{fontSize:14}} theme={isActive?"danger":"primary"} onClick={() => {handleArticlePublication()}} >{publicationButtonMessage}</Button>
              </div>
            </CardBody>
        </Card>
      </Col>
    </Row>

    <Modal open={openAddMediaModal} toggle={toggleAddMediaModal}>
        <ModalHeader style={{display:'block'}}>
            Add New Media
            <span className="float-right p-1" style={{cursor:'pointer'}} onClick={toggleAddMediaModal}>X</span>
        </ModalHeader>
        <ModalBody>
            <Form onSubmit= {submitNewMediaForm}>
                <Row form>
                  <Col md={6} className="form-group">
                    <label className="mr-3">Media Visibility</label>
                    <FormSelect value={newMediaAccessControlType} onChange={(e) => { setNewMediaAccessControlType(e.target.value) }}>
                        {renderVisibilityOptions()}
                    </FormSelect>
                    <FormFeedback>Please select a Visibility.</FormFeedback>
                  </Col>
                </Row>

                <Row form>
                  <Col md={6} className="form-group">
                    <label className="mr-3">Media Type</label>
                    <FormSelect value={newMediaType} onChange={(e) => { setNewMediaType(e.target.value) }}>
                        {renderMediaTypeOptions()}
                    </FormSelect>
                    <FormFeedback>Please select a Media Type.</FormFeedback>
                  </Col>
                </Row>

                <Row form style={{display: (newMediaType === "video"? "flex": "none")}}>
                    <Col className="form-group">
                        <label>New Video URL</label>
                        <FormInput onChange={(e)=> { setNewVideoUrl(e.target.value) }} value={newVideoUrl} invalid={newVideoUrl.search(urlRegex) === -1}/>
                        <FormFeedback>Please Input a valid URL.</FormFeedback>
                    </Col>
                </Row>

                <Row form style={{display: (newMediaType === "image"? "flex": "none")}}>
                  <Col md={6} className="form-group">
                    <label>New Image</label>
                    <div className="custom-file">
                        <input type="file" accept="image/*" className="custom-file-input" onChange={handleNewMediaInput} />
                        <label className="custom-file-label">
                          {newMediaFileName}
                        </label>
                    </div>
                  </Col>
                  <Col md={6} className="justify-content-center">
                    <div className="mb-3 d-flex justify-content-center">
                        <img
                        src={placeholderMediaImg}
                        style={{objectFit: "contain"}}
                        width="200"
                        height="150"
                        alt="img"
                        />
                    </div>
                  </Col>
                </Row>
                
                <Button block style={{fontSize:14}} type="submit">Submit</Button>
            </Form>
        </ModalBody>
    </Modal>    

    <Modal open={openDeleteMediaModal} toggle={hideDeleteMediaModal}>
        <ModalHeader style={{display:'block'}}>
            Delete Media
            <span className="float-right p-1" style={{cursor:'pointer'}} onClick={() => { hideDeleteMediaModal() }}>X</span>
        </ModalHeader>
        <ModalBody>
            <Row>
              <div className="mb-4">Are you sure you want to delete this media?</div>
            </Row>
            <Row>
              <Col md={6}>
                <Button block style={{fontSize:14}} theme="primary" onClick={handleDeleteMedia}>Delete</Button>
              </Col>
              <Col md={6}>
                <Button block style={{fontSize:14}} theme="secondary" onClick={() => { hideDeleteMediaModal() }}>Cancel</Button>
              </Col>
            </Row>
        </ModalBody>
    </Modal>

    <Modal open={openDeleteArticleModal} toggle={hideDeleteArticleModal}>
        <ModalHeader style={{display:'block'}}>
            Delete Article
            <span className="float-right p-1" style={{cursor:'pointer'}} onClick={() => { hideDeleteArticleModal() }}>X</span>
        </ModalHeader>
        <ModalBody>
            <Row>
              <div className="mb-4">Are you sure you want to delete this article?</div>
            </Row>
            <Row>
              <Col md={6}>
                <Button block style={{fontSize:14}} theme="primary" onClick={handleDeleteArticle}>Delete</Button>
              </Col>
              <Col md={6}>
                <Button block style={{fontSize:14}} theme="secondary" onClick={() => { hideDeleteArticleModal() }}>Cancel</Button>
              </Col>
            </Row>
        </ModalBody>
    </Modal>
  </Container>
 );
}

export default newArticles;
