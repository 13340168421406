import React,{useState, useEffect} from "react";
import {
    Container,
    Card,CardHeader,CardBody,
    ListGroup,ListGroupItem,
    Row,Col,
    Form,FormFeedback,FormInput,FormSelect,FormCheckbox,
    Button,
    Modal,ModalBody,ModalHeader,
  } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Pagination from "../components/common/Pagination";
import {Link, useParams} from "react-router-dom";
import AsyncSelect from 'react-select/async';

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import UserEndpoints from "../data/api-endpoints/user";
import SubscriptionEndpoints from "../data/api-endpoints/user-subscriptions";
import CompanyEndpoints from "../data/api-endpoints/company";
import UrlResolverService from "../services/UrlResolverService";

import moment from "moment";

import base64Encode from "../services/ImageBase64EncoderService";

const placeholderImage = require("../images/placeholder/no_image_placeholder.png");

function UserProfile(){
    
    const {userId} = useParams();

    const [roles, setRoles] = useState([]); 

    const [roleId, setRoleId] = useState(0); //value is integer id
    const [selectedCompanyOption, setSelectedCompanyOption] = useState(null); // value is object {value: , label: }
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [position, setPosition] = useState(null);
    const [contactNo, setContactNo] = useState(null);
    const [dob, setDob] = useState(null);
    const [isActive, setIsActive] = useState(false);

    const [subscriptions, setSubscriptions] = useState([]);
    
    const [totalSubscriptionPages, setTotalSubscriptionPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const [openSubscriptionModal,setOpenSubscriptionmodal] = useState(false);
    const [activeTime, setActiveTime] = useState("");
    const [expiryTime, setExpiryTime] = useState("");

    const [updateUserButtonDisabled, setUpdateUserButtonDisabled] = useState(false);
    const [updateUserButtonMessage, setUpdateUserButtonMessage] = useState("Update");

    const [updatePasswordButtonDisabled, setUpdatePasswordButtonDisabled] = useState(false);
    const [updatePasswordButtonMessage, setUpdatePasswordButtonMessage] = useState("Change Password");
    
    const [profileImg, setProfileImg] = useState(null);
    const [profileImgFileName, setProfileImgFileName] = useState("Select an Image");
    const [placeholderImg, setPlaceholderImg] = useState(placeholderImage);

    let timeout = null;

    function updateUserButtonIsDisabled(disabled) {
      setUpdateUserButtonDisabled(disabled);
      if (disabled) {
        setUpdateUserButtonMessage("Submitting...");
      } else {
        setUpdateUserButtonMessage("Update");
      }
    }

    function updatePasswordButtonIsDisabled(disabled) {
      setUpdatePasswordButtonDisabled(disabled);
      if (disabled) {
        setUpdatePasswordButtonMessage("Changing Password...");
      } else {
        setUpdatePasswordButtonMessage("Change Password");
      }
    }

    async function fetchUser() {
      try {
        const response = await RestService.GetRequest(UrlResolverService.resolve(
          UserEndpoints.GetUserDetails,
          { userId }
        ));
        const data = response.data;
        setUsername(data.username);
        setEmail(data.email);
        setIsActive(data.isActive);
        setFirstName(data.userDetails.firstName);
        setRoleId(data.userPermissions.id);
        setLastName(data.userDetails.lastName ? data.userDetails.lastName : null);
        setPosition(data.userDetails.position ? data.userDetails.position : null);
        setContactNo(data.userDetails.contactNo ? data.userDetails.contactNo : null);
        setDob(data.userDetails.dob ? data.userDetails.dob.substring(0, data.userDetails.dob.indexOf("T")) : null);
        setSelectedCompanyOption(data.userDetails.company? {
          value: data.userDetails.company.id,
          label: data.userDetails.company.companyName
        }: null);
        if (data.userDetails.profileImgUrl) setPlaceholderImg(data.userDetails.profileImgUrl);
      } catch (e) {
        handleError(e);
      }
    }

    async function fetchUserPermissions() {
      try {
        const response = await RestService.GetRequest(UserEndpoints.GetAllUserPermissions);
        setRoles(response.data.roles);
      } catch (e) {
        handleError(e);
      }
    }

    async function paginateSubscriptions(page) {
      try {
        const response = await RestService.GetRequest(UrlResolverService.resolve(
          SubscriptionEndpoints.PaginateSubscriptions,
          {userId, p: page, n: itemsPerPage}
        ));
        setSubscriptions(response.data.page.content);
        setTotalSubscriptionPages(response.data.page.totalPages);
      } catch (e) {
        handleError(e);
      }
    }

    async function handlePagination(page) {
      await paginateSubscriptions(page);
    }

    useEffect(()=> {
      fetchUser();
      fetchUserPermissions();
      paginateSubscriptions(1);
    }, []);

    const mapCompanyNames = (results) => {
      return results.map(result => ({
        value: result.id,
        label: result.companyName
      }));
    };
  
    function fetchCompanyNames(inputValue, callback) {
      timeout = setTimeout(() => {
        RestService.GetRequest(UrlResolverService.resolve(
          CompanyEndpoints.SearchCompanyName,
          { q: inputValue }
        )).then(response => {
          callback(mapCompanyNames(response.data.results));
        }).catch(e => {
          handleError(e);
        });
      }, 1000);
    }

    function handleCompanyInputChange(input) {
      if (timeout != null) {
        clearTimeout(timeout);
      } 

      const inputValue = input.replace(/[^a-zA-Z0-9 ]/g, '');
      return inputValue;
    }

    function toggleSubscriptionModal(){
      setActiveTime("");
      setExpiryTime("");
      setOpenSubscriptionmodal(!openSubscriptionModal);
    }

    function handleProfileImgInput(e) {
      setProfileImg(e.target.files[0]); 
      setProfileImgFileName(e.target.files[0].name);
      setPlaceholderImg(URL.createObjectURL(e.target.files[0]));
    }

    async function submitUpdateUserForm(e) {
      updateUserButtonIsDisabled(true);
      e.preventDefault();
      if (roleId !==0 && firstName) {
        try {

          const data = {
            roleId,
            companyId: (selectedCompanyOption? selectedCompanyOption.value: null),
            isActive,
            firstName,
            lastName,
            position,
            contactNo,
            dob,
          };
          if (profileImg) data.profileImg = await base64Encode(profileImg, 600, 600);

          const response = await RestService.PutRequest(
            UrlResolverService.resolve(
              UserEndpoints.UpdateUser,
              { userId }
            ), 
            data
          );
          window.flashMessage(response.data.resultStatus.message);
          updateUserButtonIsDisabled(false);
        } catch (e) {
          handleError(e);
          updateUserButtonIsDisabled(false);
        }
      }
    }

    async function submitChangePasswordForm(e) {
      updatePasswordButtonIsDisabled(true);
      e.preventDefault();
      if (password.length >= 8 && password === confirmPassword) {
        try {
          const data = {
            password
          };
  
          const response = await RestService.PutRequest(
            UrlResolverService.resolve(
              UserEndpoints.ChangePassword,
              { userId }
            ),
            data
          );

          window.flashMessage(response.data.resultStatus.message);

          setPassword("");
          setConfirmPassword("");
          updatePasswordButtonIsDisabled(false);
        } catch (e) {
          handleError(e);
          updatePasswordButtonIsDisabled(false);
        }
      }
    }
    
    async function submitSubscriptionForm(e) {
      e.preventDefault();
      if (expiryTime !== "" && activeTime !== "") {
        try {
          const data = {
            activeTime,
            expiryTime
          };

          const response = await RestService.PostRequest(
            UrlResolverService.resolve(
              SubscriptionEndpoints.CreateSubscriptions,
              {userId}
            ),
            data
          );
          window.flashMessage(response.data.resultStatus.message);
          
          subscriptions.unshift({
            id: response.data.id,
            userId: response.data.userId,
            expiryTime: response.data.expiryTime,
            activeTime: response.data.activeTime,
            gmtCreate: response.data.gmtCreate,
            gmtModified: response.data.gmtModified
          });
          toggleSubscriptionModal();          
        } catch (e) {
          handleError(e);
        }
      }
    }

    function renderRoles() {
      return roles.map((role, i) => (
        <option key={i} value={role.id}>{role.roleName}</option>
      )); 
    }

    function renderSubscriptionDate(){
        return subscriptions.map((subscription,i)=>(
            <tr key={i}>
                <td>{subscription.id}</td>
                <td>{moment(subscription.activeTime).format("MMM Do YYYY, h:mm a")}</td>
                <td>{moment(subscription.expiryTime).format("MMM Do YYYY, h:mm a")}</td>
            </tr>
        ))
    }
    
return (
    <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header pt-4 pb-2">
        <PageTitle sm="4" title="Member Profile" subtitle="Member" className="text-sm-left" />
    </Row>
    <div className="mb-2"><Link to="/accounts"> &#8636; Go Back</Link></div>

    <Row>
      <Col md={7}>
        <Card small className="mb-4 ">
            <CardHeader className="border-bottom">
              <h6 className="m-0">User Info</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Form onSubmit={submitUpdateUserForm}>
                    <Row form>
                        <Col md={6} className="form-group">
                            <label>Email</label>
                            <p><b>{email}</b></p>
                        </Col>
                        <Col md={6} className="form-group">
                            <label>Username</label>
                            <p><b>{username}</b></p>
                        </Col>
                    </Row>
                    <Row form>
                      <Col md={4} className="form-group">
                        <label>Role</label><label style={{color: "red"}}>*</label>
                        <FormSelect required value={roleId} onChange={(e) => {setRoleId(e.target.value)}} invalid={roleId===0}>
                          {renderRoles()}
                        </FormSelect>
                      </Col>
                      <Col md={{span: 6, offset: 2}} className="form-group">
                        <label>Active User</label>
                        <FormCheckbox toggle checked={isActive} onChange={() => {setIsActive(!isActive)}}>
                        </FormCheckbox>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6} className="form-group">
                          <label>First Name</label><label style={{color: "red"}}>*</label>
                          <FormInput required value={firstName? firstName: ""} onChange={(e) => {setFirstName(e.target.value)}}/>
                          <FormFeedback></FormFeedback>
                      </Col>
                      <Col md={6} className="form-group">
                          <label>Last Name</label>
                          <FormInput value={lastName? lastName: ""} onChange={(e) => {setLastName(e.target.value)}}/>
                          <FormFeedback></FormFeedback>
                      </Col>
                    </Row>
                    <Row form>
                        <Col md={12} className="form-group">
                            <label>Partner Company</label>
                            <AsyncSelect 
                              value = {selectedCompanyOption}
                              placeholder="Search" 
                              cacheOptions 
                              loadOptions={fetchCompanyNames}  
                              onInputChange={handleCompanyInputChange}
                              onChange={(option) => {setSelectedCompanyOption(option)}}
                            />
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6} className="form-group">
                            <label>Position</label>
                            <FormInput value={position? position : ""} onChange={(e) => {setPosition(e.target.value)}}/>
                        </Col>
                        <Col md={6} className="form-group">
                            <label>Phone</label>
                            <FormInput value={contactNo? contactNo: ""} onChange={(e) => {setContactNo(e.target.value)}}/>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={12} className="form-group">
                            <label>Date of Birth </label>
                            <FormInput type="date" value={dob? dob: ""} onChange={(e) => {setDob(e.target.value) }}/>
                        </Col>
                    </Row>

                    <Row form style={{ marginBottom: "30px" }}>
                      <Col md={6} className="form-group">
                        <label>Profile Image</label>
                        <div className="custom-file mb-3">
                          <input type="file" accept="image/*" className="custom-file-input" onChange={handleProfileImgInput} />
                          <label className="custom-file-label">
                          {profileImgFileName}
                          </label>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 d-flex justify-content-center">
                          <img
                          src={placeholderImg}
                          style={{objectFit: "contain"}}
                          width="200"
                          height="200"
                          alt="img"
                          />
                        </div>
                      </Col>
                    </Row>

                    <div>*Required Field</div>
                    <div className="d-flex justify-content-center mb-2">
                        <Button disabled={updateUserButtonDisabled} block style={{fontSize:16}} type="submit">{updateUserButtonMessage}</Button>
                    </div>
                </Form>
              </ListGroupItem>
            </ListGroup>
          </Card>
      </Col>
      <Col md={5}>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Change Password</h6>
          </CardHeader>

          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Form onSubmit={submitChangePasswordForm}>
                <Row form>
                    <Col md={12} className="form-group">
                        <label>New Password</label>
                        <FormInput required value={password} type="password" onChange={(e) => {setPassword(e.target.value)}} invalid={(password.length !==0 && password.length < 8)}/>
                        <FormFeedback>Password needs to be 8 characters long.</FormFeedback>
                    </Col>
                </Row>
                <Row form>
                  <Col md={12} className="form-group">
                    <label>Confirm New Password</label>
                    <FormInput required value={confirmPassword} type="password" onChange={(e) => {setConfirmPassword(e.target.value)}} invalid={(confirmPassword.length !==0 && password !== confirmPassword)} />
                    <FormFeedback>Password does not match.</FormFeedback>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center mb-2">
                    <Button disabled={updatePasswordButtonDisabled} block style={{fontSize:16}} type="submit">{updatePasswordButtonMessage}</Button>
                </div>
              </Form>
            </ListGroupItem>
          </ListGroup>
        </Card>
        
        <Card>
          <CardHeader className="border-bottom">
            <Row>
                <Col><h6 className="m-0">Subcription</h6></Col>
                <Col><Button style={{fontSize:14}} theme="success" onClick={()=>toggleSubscriptionModal()}>New Subscription</Button></Col>
            </Row>
              
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                    ID
                </th>
                <th scope="col" className="border-0">
                    Active Time
                </th>
                <th scope="col" className="border-0">
                    Expiry Time
                </th>
              </tr>
            </thead>
            <tbody>
                {renderSubscriptionDate()}
            </tbody>
            </table>
            
            <nav style={{cursor:'pointer'}}>
              <Pagination totalPages={totalSubscriptionPages} pageNeighbors={2} onPageChanged={handlePagination}/>
            </nav>
          </CardBody>
        </Card>

      </Col>
    </Row>
    <Modal open={openSubscriptionModal} toggle={toggleSubscriptionModal}>
        <ModalHeader style={{display:'block'}}>
            Create New Subscription
            <span className="float-right p-1" style={{cursor:'pointer'}} onClick={toggleSubscriptionModal}>X</span>
        </ModalHeader>
        <ModalBody>
            <div className="mb-4">Subscriber: {username}</div>
            <Form onSubmit= {submitSubscriptionForm}>
                <Row form>
                    <Col className="form-group">
                        <label>Start Date</label>
                        <FormInput required type="date" onChange={(e)=>setActiveTime(e.target.value)} value={activeTime}/>
                    </Col>
                </Row>
                <Row form>
                    <Col className="form-group">
                        <label>Expiration Date</label>
                        <FormInput required type="date" onChange={(e)=>setExpiryTime(e.target.value)} value={expiryTime}/>
                    </Col>
                </Row>
                <Button block style={{fontSize:14}} type="submit">Submit</Button>
            </Form>
        </ModalBody>
    </Modal>
    
  </Container>
 );
}

export default UserProfile;
