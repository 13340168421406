import React,{useState, useEffect} from "react";
import {
  Container,
  Card,CardHeader,CardBody,
  Row,Col,
  Form,FormInput,FormFeedback,
  ListGroup,ListGroupItem,
  Button
} from "shards-react";
import {Redirect, useLocation, useHistory} from "react-router-dom";

import handleError from "../services/ErrorHandlerService";
import AuthenticationService from "../services/AuthenticationService";


const containerStyle = {
  margin: 0,
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)'
};

const imgStyle = {
  objectFit: "contain",
  display: 'block',
  margin: '0 auto'
};

const Login = () => {

  const location = useLocation();
  let from = "/";
  if (location.state) {
    if (location.state.from) from = location.state.from;
  }

  async function validateLoggedIn() {
    try {
      if (!location.state) {
        const response = await AuthenticationService.getAuthenticationInfo();
        if (response.role === "admin" && response.isSignedIn) {
          history.replace(from);
          setRedirect(true);
        }
      }
    } catch (e) {
      handleError(e);
    }
    
  }

  useEffect(() => {
    validateLoggedIn();
  }, []);

  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [redirect, setRedirect] = useState(false);

  function renderRedirect() {
    if (redirect) return <Redirect to={from}/>;
  }

  async function handleLoginForm(e) {
    e.preventDefault();
    try {
      await AuthenticationService.authenticateWithRoleAdmin(email, password);
      history.replace(from);
      setRedirect(true);
    } catch (e) {
      handleError(e);
    }
  }

  return (
    <Container fluid className="main-content-container px-4" >
      {renderRedirect()}
      <Row className="justify-content-center">
        <Col md={3} style={containerStyle}>

          <Row>
            <Col md={12} className="justify-content-center">
              <img style={imgStyle} width="200" height="100" src={require("../images/logo/logo.png")} alt="Sales Director Indonesia"/>
            </Col>
          </Row>

          <Row>
            <Col md={12}>

              <Card>
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Admin Login</h6>
                </CardHeader>

                <CardBody className="p-0 pb-3">
                  <ListGroup flush>
                    <ListGroupItem className="p-3">
                      <Form onSubmit={handleLoginForm}>
                        <Row form>
                          <Col md={12} className="form-group">
                            <label>Email</label>
                            <FormInput required value={email} onChange={(e) => {setEmail(e.target.value)}}/>
                            <FormFeedback>Email is required.</FormFeedback>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md={12} className="form-group">
                            <label>Password</label>
                            <FormInput required type="password" value={password} onChange={(e) => {setPassword(e.target.value)}}/>
                            <FormFeedback>Password is required.</FormFeedback>
                          </Col>
                        </Row>
                        <div className="d-flex justify-content-center mb-2">
                          <Button block style={{fontSize:16}} type="submit">Submit</Button>
                        </div>
                      </Form>
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>

            </Col>
          </Row>

        </Col>
      </Row>
    </Container>
  );
}

export default Login;