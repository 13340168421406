import React,{useState, useEffect} from "react";
import {
    Container,
    Card,CardHeader,
    ListGroup,ListGroupItem,
    Row,Col,
    Form,FormFeedback,FormInput,FormTextarea,
    Button,
  } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {Link, useParams} from "react-router-dom";

import base64Encode from "../services/ImageBase64EncoderService";

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import CompanyEndpoints from "../data/api-endpoints/company";
import UrlResolverService from "../services/UrlResolverService";

const placeholderImage = require("../images/placeholder/no_image_placeholder.png");

function CompanyProfile(){

  const {companyId} = useParams();

  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyIndustry, setCompanyIndustry] = useState("");
  const [companyContactNo, setCompanyContactNo] = useState("");
  const [companyWebUrl, setCompanyWebUrl] = useState("");

  const [companyLogo, setCompanyLogo] = useState(null);
  const [logoFileName, setLogoFileName] = useState("Select an Image");
  const [placeholderImg, setPlaceholderImg] = useState(placeholderImage);

  const [logoOrderPriority, setLogoOrderPriority] = useState(99999);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonMessage, setButtonMessage] = useState("Update");

  async function fetchCompanyProfile() {
    try {
      const response = await RestService.GetRequest(UrlResolverService.resolve(
        CompanyEndpoints.GetCompanyById,
        { companyId }
      ));

      const data = response.data;
      setCompanyName(data.companyName);
      if (data.companyAddress) setCompanyAddress(data.companyAddress);
      if (data.companyDescription) setCompanyDescription(data.companyDescription);
      if (data.companyEmail) setCompanyEmail(data.companyEmail);
      if (data.companyIndustry) setCompanyIndustry(data.companyIndustry);
      if (data.setCompanyContactNo) setCompanyContactNo(data.setCompanyContactNo);
      if (data.companyLogoUrl) setPlaceholderImg(data.companyLogoUrl);
      if (data.logoOrderPriority) setLogoOrderPriority(data.logoOrderPriority);
      if (data.companyWebUrl) setCompanyWebUrl(data.companyWebUrl);
    } catch (e) {
      handleError(e);
    }
  }

  useEffect(() => {
    fetchCompanyProfile();
  }, []);
  
  function handleFileInput(e) {
    setCompanyLogo(e.target.files[0]); 
    setLogoFileName(e.target.files[0].name);
    setPlaceholderImg(URL.createObjectURL(e.target.files[0]));
  }

  async function submitForm(e) {
    buttonIsDisabled(true);
    e.preventDefault();
    if (companyName !== "") {
      try {
        const data = {
          companyName,
          companyAddress,
          companyDescription,
          companyEmail,
          companyIndustry,
          companyContactNo,
          logoOrderPriority,
          companyWebUrl
        };
        if (companyLogo) {
          const base64 = await base64Encode(companyLogo, 400, 400);
          data.companyLogo = base64;
        }
        const response = await RestService.PutRequest(
          UrlResolverService.resolve(
            CompanyEndpoints.UpdateCompany,
            { companyId }
          ),
          data
        );
        window.flashMessage(response.data.resultStatus.message);
        buttonIsDisabled(false);
      } catch (e) {
        handleError(e);
        buttonIsDisabled(false);
      }
    }
  }

  function buttonIsDisabled(disabled) {
    setButtonDisabled(disabled);
    if (disabled) {
      setButtonMessage("Submitting...");
    } else {
      setButtonMessage("Update");
    }
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
      <Row noGutters className="page-header pt-4 pb-2">
          <PageTitle sm="4" title="Partner Company Profile" subtitle="Partner" className="text-sm-left" />
      </Row>
      <div className="mb-2"><Link to="/partners"> &#8636; Go Back</Link></div>

      <Row className="justify-content-center">
        <Col md={8}>
          <Card small className="mb-4 ">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Partner Company Profile</h6>
              </CardHeader>

              <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Form onSubmit={submitForm}>
                      <Row form>
                          <Col md={6} className="form-group">
                              <label>Partner Name</label><label style={{color: "red"}}>*</label>
                              <FormInput required value={companyName} onChange={(e) => {setCompanyName(e.target.value)}}/>
                              <FormFeedback></FormFeedback>
                          </Col>
                      </Row>
                      <Row form>
                          <Col md={12} className="form-group">
                              <label>Partner Short Description</label>
                              <FormTextarea rows="3" value ={companyDescription}  onChange={(e) => {setCompanyDescription(e.target.value)}} />
                          </Col>
                      </Row>
                      <Row form>
                          <Col md={12} className="form-group">
                              <label>Partner Address</label>
                              <FormInput value = {companyAddress}  onChange={(e) => {setCompanyAddress(e.target.value)}} />
                          </Col>
                      </Row>

                      <Row form>
                            <Col md={6} className="form-group">
                                <label>Partner Email</label>
                                <FormInput type="email" value = {companyEmail}  onChange={(e) => {setCompanyEmail(e.target.value)}} />
                            </Col>
                            <Col md={6} className="form-group">
                                <label>Partner Industry</label>
                                <FormInput value = {companyIndustry}  onChange={(e) => {setCompanyIndustry(e.target.value)}} />
                            </Col>
                      </Row>
                      <Row form>
                          <Col md={6} className="form-group">
                              <label>Partner Contact No.</label>
                              <FormInput value = {companyContactNo}  onChange={(e) => {setCompanyContactNo(e.target.value)}}/>
                          </Col>
                          <Col md={6} className="form-group">
                                  <label>Partner Web URL</label>
                                  <FormInput value = {companyWebUrl}  onChange={(e) => {setCompanyWebUrl(e.target.value)}}/>
                              </Col>
                      </Row>
                      
                      <Row form>
                        <Col md={6} className="form-group">
                            <label>Change Logo</label>
                            <div className="custom-file mb-3">
                                <input type="file" accept="image/*" className="custom-file-input" onChange={handleFileInput} />
                                <label className="custom-file-label">
                                  {logoFileName}
                                </label>
                            </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 d-flex justify-content-center">
                              <img
                              src={placeholderImg}
                              style={{objectFit: "contain"}}
                              width="200"
                              height="200"
                              alt="img"
                              />
                          </div>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={6} className="form-group">
                          <label>Order Priority</label><label style={{color: "red"}}>*</label>
                          <FormInput type="number" value={logoOrderPriority} onChange={(e) => {setLogoOrderPriority(e.target.value)}} required/>
                          <FormFeedback></FormFeedback>
                        </Col>
                      </Row>

                      <div className="mb-3"><span style={{color: "red"}}>*</span>Required Field</div>
                      <div className="d-flex justify-content-center mb-2">
                          <Button disabled={buttonDisabled} block style={{fontSize:16}} type="submit">{buttonMessage}</Button>
                      </div>
                    </Form>
                  </ListGroupItem>
              </ListGroup>
          </Card>
        </Col>
      </Row>

  </Container>
 );
}

export default CompanyProfile;
