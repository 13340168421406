import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import routes from "./routes";
import Authentication from "./components/AuthenticationMiddleware";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

import EventService from "./services/EventService";
import FlashMessage from "./components/common/FlashMessage";

export default () => { 
  window.flashMessage = (message, type="success", timeout = 4000) => {
    EventService.emit('flash-message', ({message, type, timeout}));
  };

  return (
    <div>
      <FlashMessage/>
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props => {
                  if (route.noAuth) {
                    return (
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    );
                  } else {
                    return (
                      <Authentication 
                        fromPath={route.noRedirect? null: route.path} 
                        Layout={route.layout} 
                        Component={route.component}
                        props={props}
                      />
                    );
                  }
                })}
              />
            );
          })}
        </Switch>
    
      </Router>
    </div>
  )
};
