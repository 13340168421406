import React,{useState} from "react";
import {
    Container,
    Card,CardHeader,CardBody,
    Row,Col,
    Form,FormInput,FormFeedback,FormTextarea,
    Button,
  } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {Link, Redirect} from "react-router-dom";

import base64Encode from "../services/ImageBase64EncoderService";

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import BoardMemberEndpoints from "../data/api-endpoints/board-member";

const placeholderImage = "https://via.placeholder.com/200";

function NewBoardMember() {
  const [name, setName] = useState("");
  const [sdiTitle, setSdiTitle] = useState("");
  const [originCompany, setOriginCompany] = useState("");
  const [originCompanyTitle, setOriginCompanyTitle] = useState("");
  const [description, setDescription] = useState("");
  const [orderNo, setOrderNo] = useState(1);

  const isActive = true;

  const [profileImg, setProfileImg] = useState(null);
  const [profileImgFileName, setProfileImgFileName] = useState("Select an Image");
  const [placeholderImg, setPlaceholderImg] = useState(placeholderImage);

  const [createdRedirect, setCreatedRedirect] = useState(false);
 
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonMessage, setButtonMessage] = useState("Submit");

  async function handleSubmitForm(e) {
    buttonIsDisabled(true);
    e.preventDefault();
    try {
      const data = {
        name,
        sdiTitle,
        originCompany,
        originCompanyTitle,
        description,
        orderNo,
        isActive,
      };
      if (profileImg) data.profileImg = await base64Encode(profileImg, 600, 600);

      const response = await RestService.PostRequest(
        BoardMemberEndpoints.CreateBoardMember,
        data
      );

      window.flashMessage(response.data.resultStatus.message);
      buttonIsDisabled(false);
      setCreatedRedirect(true);
    } catch (error) {
      handleError(error);
      buttonIsDisabled(false);
    }
  }

  function handleFileInput(e) {
    setProfileImg(e.target.files[0]); 
    setProfileImgFileName(e.target.files[0].name);
    setPlaceholderImg(URL.createObjectURL(e.target.files[0]));
  }

  const renderRedirect = () => {
    if (createdRedirect) return <Redirect to="/board-members"/>;
  }

  function buttonIsDisabled(disabled) {
    setButtonDisabled(disabled);
    if (disabled) {
      setButtonMessage("Submitting...");
    } else {
      setButtonMessage("Submit");
    }
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {renderRedirect()}

      {/* Page Header */}
      <Row noGutters className="page-header pt-4 pb-2">
        <PageTitle sm="4" title="New Board Member" subtitle="Board Member" className="text-sm-left" />
      </Row>
      <div className="mb-2"><Link to="/board-members"> &#8636; Go Back</Link></div>
      <Row className="justify-content-center">
        <Col lg={8}>
          <Card small className="mb-4 ">
            <CardHeader className="border-bottom">
              <h6 className="m-0">New Board Member</h6>
            </CardHeader>

            <CardBody>
              <Form onSubmit = {handleSubmitForm}>

                <Row form>
                  <Col md={3} className="form-group">
                    <label>Order Priority</label><label style={{color: "red"}}>*</label>
                    <FormInput type="number" value={orderNo} onChange={(e) => {setOrderNo(e.target.value)}} required/>
                    <FormFeedback></FormFeedback>
                  </Col>
                </Row>

                <Row form>
                  <Col md={6} className="form-group">
                    <label>Board Member Name</label><label style={{color: "red"}}>*</label>
                    <FormInput value={name} onChange={(e) => {setName(e.target.value)}} required/>
                    <FormFeedback></FormFeedback>
                  </Col>
                </Row>

                <Row form>
                  <Col md={6} className="form-group">
                    <label>Title in SDI</label><label style={{color: "red"}}>*</label>
                    <FormInput value={sdiTitle} onChange={(e) => {setSdiTitle(e.target.value)}} required/>
                    <FormFeedback></FormFeedback>
                  </Col>
                </Row>

                <Row form>
                  <Col md={6} className="form-group">
                    <label>Origin Company Name</label><label style={{color: "red"}}>*</label>
                    <FormInput value={originCompany} onChange={(e) => {setOriginCompany(e.target.value)}} required/>
                    <FormFeedback></FormFeedback>
                  </Col>
                
                  <Col md={6} className="form-group">
                    <label>Title in Origin Company</label><label style={{color: "red"}}>*</label>
                    <FormInput value={originCompanyTitle} onChange={(e) => {setOriginCompanyTitle(e.target.value)}} required/>
                    <FormFeedback></FormFeedback>
                  </Col>
                </Row>

                <Row form>
                  <Col md={12} className="form-group">
                    <label>Board Member Description</label>
                    <FormTextarea rows="3" value ={description} onChange={(e) => {setDescription(e.target.value)}} />
                  </Col>
                </Row>

                <Row form style={{ marginBottom: "30px" }}>
                  <Col md={6} className="form-group">
                    <label>Profile Image</label><label style={{color: "red"}}>*</label>
                    <div className="custom-file mb-3">
                      <input required type="file" accept="image/*" className="custom-file-input" onChange={handleFileInput} />
                      <label className="custom-file-label">
                      {profileImgFileName}
                      </label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3 d-flex justify-content-center">
                      <img
                      src={placeholderImg}
                      style={{objectFit: "contain"}}
                      width="200"
                      height="200"
                      alt="img"
                      />
                    </div>
                  </Col>
                </Row>

                <div className="mb-3"><span style={{color: "red"}}>*</span>Required Field</div>
                <div className="d-flex justify-content-center mb-2">
                  <Button disabled={buttonDisabled} block style={{fontSize:16}} type="submit">{buttonMessage}</Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
 
}

export default NewBoardMember;