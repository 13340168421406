import React,{useState,useEffect} from "react";
import {
    Container,
    Card,CardHeader,CardBody,
    Row,Col,
    Form,FormInput,InputGroupText,
    InputGroup,InputGroupAddon,
    Button,
  } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Pagination from "../components/common/Pagination";
import {Link} from "react-router-dom";

import handleError from "../services/ErrorHandlerService";
import RestService from "../services/RestService";
import CompanyEndpoints from "../data/api-endpoints/company";
import UrlResolverService from "../services/UrlResolverService";

function Partners(){

  const [companies, setCompanies] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  async function paginateCompanies(page) {
    try {
      const response = await RestService.GetRequest(UrlResolverService.resolve(
        CompanyEndpoints.PaginateCompanies,
        { p: page, n: itemsPerPage }
      ));
      setCompanies(response.data.page.content);
      setTotalPages(response.data.page.totalPages);
    } catch (e) {
      handleError(e);
    }
  }

  async function handlePagination(page) {
    await paginateCompanies(page);
  }

  useEffect(() => {
    paginateCompanies(1);
  }, []);

  function renderCompanies() {
    return companies.map((company, i) => (
      <tr key = {i}>
        <td>{company.logoOrderPriority}</td>
        <td>{company.companyName}</td>
        <td>
            <Link to={'/partners/'+company.id}>
                <Button>View</Button>
            </Link>
        </td>
    </tr>
    ));
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header pt-4 pb-2">
          <PageTitle sm="4" title="Partner Company List" subtitle="Partner" className="text-sm-left" />
      </Row>
        

      <Row>
        <Col md={12}>
            <Row className="mb-2">
              {/* TODO: Searchbar */}
              {/* <Col md="7">
                  <Card small className="p-1">
                  <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
                      <InputGroup seamless className="ml-3">
                      <InputGroupAddon type="prepend">
                          <InputGroupText>
                          <i className="material-icons">search</i>
                          </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                          className="navbar-search"
                          placeholder="Search for something..."
                      />
                      </InputGroup>
                  </Form>
                  </Card>
              </Col> */}
              <Col>
                  <div className="float-right">
                      <Link to="/partners/new">
                          <Button style={{fontSize:16}} theme="success">Add New Partner Company</Button>
                      </Link>
                  </div>
              </Col>
            </Row>
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">Active Users</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                    <thead className="bg-light">
                        <tr>
                        <th scope="col" className="border-0">
                            Priority
                        </th>
                        <th scope="col" className="border-0">
                            Company Name
                        </th>
                        <th scope="col" className="border-0">
                            Action
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderCompanies()}
                    </tbody>
                    </table>
                    
                    <nav style={{cursor:'pointer'}}>
                      <Pagination totalPages={totalPages} pageNeighbors={2} onPageChanged={handlePagination}/>
                    </nav>
                    
                </CardBody>
            </Card>
        </Col>
      </Row>

    </Container>
  );
}

export default Partners;
