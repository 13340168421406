import React, { useEffect, useState } from 'react';
import {Alert} from "shards-react";

import EventService from "../../services/EventService";

const style = {
  position: 'fixed',
  top: '80px',
  right: '10px',
  zIndex: 99999
};

const FlashMessage = () => {
  const [visibility, setVisibility] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    EventService.addListener('flash-message', ({message, type, timeout}) => {
      setVisibility(true);
      setMessage(message);
      setType(type);
      setTimeout(() => {
        setVisibility(false);
      }, timeout);
    });
  }, []);

  return (
    <Alert dismissible={() => {setVisibility(false)}} theme= {type} style={style} open={visibility}>
      {message}
    </Alert> 
  );

}

export default FlashMessage;