import React from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";

export default ({ 
  onSubmit = (e) => {e.preventDefault(); }, 
  placeholder ="Search for something...", 
  value="",
  onChange = (e) => { }
}) => (
  <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex" onSubmit = {onSubmit}>
    <InputGroup seamless className="ml-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>
          <i className="material-icons">search</i>
        </InputGroupText>
      </InputGroupAddon>
      <FormInput
        className="navbar-search"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </InputGroup>
  </Form>
);
