import React, {useState} from "react";
import { Redirect } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";

import AuthenticationService from "../../../../services/AuthenticationService";


const UserActions = ({username}) => {
  const [visible, setVisible] = useState(false);
  const [redirectLogin, setRedirectLogin] = useState(false);

  function toggleUserActions() {
    setVisible(!visible);
  }

  function renderRedirect() {
    if (redirectLogin) return (
      <Redirect to="/login"/>
    );
  }

  async function logout() {
    await AuthenticationService.logout(); 
    setRedirectLogin(true);
  }

  return (
    <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
      {renderRedirect()}
      <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
        <img
          className="user-avatar rounded-circle mr-2"
          src={require("./../../../../images/avatars/placeholder.jpg")}
          alt="User Avatar"
        />{" "}
        <span className="d-none d-md-inline-block">{username}</span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={visible}>
        <DropdownItem onClick={() => { logout() }} className="text-danger">
          <i className="material-icons text-danger">&#xE879;</i> Logout
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
}

export default UserActions;

// export default class UserActions extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       visible: false,
//       redirectLogin: false
//     };

//     this.toggleUserActions = this.toggleUserActions.bind(this);
//   }

//   toggleUserActions() {
//     this.setState({
//       visible: !this.state.visible
//     });
//   }

//   renderRedirect() {
//     if (this.state.redirectLogin) return (
//       <Redirect to="/login"/>
//     );
//   }

//   logout() {
//     let history = useHistory();
//     AuthenticationService.logout();
//     history.push("/");
//     this.setState({
//       redirectLogin: true
//     });
//   }

//   render() {
//     return (
//       <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
//         {this.renderRedirect()}
//         <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
//           <img
//             className="user-avatar rounded-circle mr-2"
//             src={require("./../../../../images/avatars/0.jpg")}
//             alt="User Avatar"
//           />{" "}
//           <span className="d-none d-md-inline-block">Sierra Brooks</span>
//         </DropdownToggle>
//         <Collapse tag={DropdownMenu} right small open={this.state.visible}>
//           <DropdownItem onClick={() => {this.logout()}} className="text-danger">
//             <i className="material-icons text-danger">&#xE879;</i> Logout
//           </DropdownItem>
//         </Collapse>
//       </NavItem>
//     );
//   }
// }
